/* Module: S */
body {
	&.fixed {
		overflow: auto;
    }
}

.navigation {
    position: static;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    background-color: transparent;
    display: block;

    &__toggle {
        display: none;
    }

    &__info {
        width: 20%;
        max-width: pxToRem(240);
        float: left; 
        display: block;    
        color: $white-four; 
        padding: pxToRem(26) 0;
    }

    &__title {
        font-size: 1.750em; //28
        font-weight: 500;
        line-height: 1.29;
    }

    &__description {
        font-size: 0.875em; //14
        line-height: 1.57;
        padding-top: pxToRem(44);
        position: relative;

        &:after {
            content: '';
            width: pxToRem(40);
            height: pxToRem(2);
            display: block;
            background-color: $greyblue;
            position: absolute;
            top: pxToRem(20);
            left: 0;
        }
    }

    &__level-1 {
        padding: pxToRem(45) 0 0 0;
        float: left;

        >li {
        	float: left;

    		&:hover {
				.navigation__level-2-wrapper {
                    opacity: 1;
                    visibility: visible;
                    transition: all 0.001s;
                    transition-delay: 0.2s;
				}

                button {
                    &:before {
                        opacity: 1;
                        visibility: visible;
                        transition: all 0.001s;
                        transition-delay: 0.2s;
                    }
                }
    		}
        }

        &-link {
        	border: none;

            button {
                font-size: 1em; //16
                padding: pxToRem(20) pxToRem(40) pxToRem(35) pxToRem(15);

                &:after {
                    margin-top: pxToRem(-12);
                }

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: pxToRem(28);
                    height: pxToRem(2);
                    background-color: $teal-blue-two;
                    left: pxToRem(15);
                    right: pxToRem(15);
                    opacity: 0;
                    visibility: hidden;
                }

                &:after {
                    right: pxToRem(15);
                    @include sq-transition(none);
                }

                &:hover,
                &:focus,
                &.active,
                &.current {
                    background-color: transparent;

                    &:before {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                &.active {
                    &:after {
                        @include sq-transform(rotate(0deg));
                    }
                }
            }

            a {
                font-size: 1em; //16
                padding: pxToRem(10) pxToRem(40) pxToRem(9) pxToRem(20);
                margin: pxToRem(10) 0 0 pxToRem(10);
                background-color: $white-six;
                border-radius: pxToRem(30);
                width: auto;

                &:hover,
                &:focus,
                &.current {
                    background-color: $light-grey;
                }

                &:after {
                    right: pxToRem(15);
                }

                &.external-link {
                    &:after {
                        content: url(../mysource_files/icon-external-link.svg);
                        display: inline-block;
                        height: 13px;
                        width: 13px;
                        @include sq-transform(rotate(0deg));
                    }
                }
            }
        }
    }

    &__level-2 {
        width: 40%;
        max-width: pxToRem(400);
        float: left;
        padding-left: pxToRem(60);
        position: relative;
        background: transparent;

        &-wrapper {
            position: fixed;
		    top: pxToRem(160);
            height: pxToRem(430);
		    left: 0;
		    right: 0;
		    padding: pxToRem(34) pxToRem(130);
            background-color: $teal-blue;
            background-image: linear-gradient(111deg, $teal-blue-two, $ocean);
            @include font-smoothing;
            display: block;
            opacity: 0;
            visibility: hidden;

            &.active {
                opacity: 1;
                visibility: visible;
            }
        }

        > li {
            border: none;

            &:hover {
                .navigation__level-2-link {
                    a {
                        background-color: $ocean;

                        &:after {
                            left: pxToRem(30);
                            opacity: 1;
                        }
                    }
                }

                .navigation__level-3 {
                    display: block;
                }
            }
        }

        &-link {
            a {
                padding: pxToRem(16) pxToRem(25) pxToRem(16) pxToRem(64);

                &:hover,
                &:focus,
                &.active,
                &.current {
                    background-color: $ocean;

                    &:after {
                        left: pxToRem(30);
                        opacity: 1;
                    }
                } 

                &:after {
                    content: url(../mysource_files/arrow-long-right-white.svg);
                    width: pxToRem(25);
                    height: pxToRem(16);
                    line-height: pxToRem(16);
                    display: block;
                    position: absolute;
                    top: 50%;
                    margin-top: pxToRem(-8);
                    left: pxToRem(25);
                    @include sq-transition(left 0.3s, opacity 0.3s);
                    opacity: 0.4;
                }           
            }

            button {
                display: none;
            }
        }
    }

    &__level-3 {
        position: absolute;
        top: 0;
        left: 100%;
        height: 100%;
        width: 100%;
        min-width: pxToRem(440);
        padding-left: pxToRem(20);
        background-color: transparent;
        border: none;

        li {
        	border: none;

            &.current {
                a {
                    background-color: $ocean;
                }
            }

        	a {
                padding: pxToRem(6) pxToRem(20) pxToRem(6) pxToRem(54);
                font-size: 0.938em; //15
                line-height: 1.2;

                &:after {
                    @include sq-transition(left 0.3s);
                }

                &:hover,
                &:focus {
                    background-color: $ocean;

                    &:after {
                        left: pxToRem(22);
                    }
                }
        	}

        }
    }

    &__secondary {
        position: absolute;
        top: 0;
        right: pxToRem(110);

        li {
            border: none;
            float: left;
            position: relative;
            margin-right: pxToRem(-15);

            &:first-child {
                a {
                    padding-left: pxToRem(30);
                }
            }

            &:last-child {
                a {
                    padding-right: pxToRem(50);
                }
            }
        }

        a {
            font-size: 0.875em; //14
            padding: pxToRem(11) pxToRem(30) pxToRem(10) pxToRem(15);
            background-color: $white-four;
            border-top-left-radius: pxToRem(100);
            border-bottom-left-radius: pxToRem(100);

            &:hover,
            &:focus {
                background-color: $light-grey;
            }

            &.current {
                color: $battleship-grey-two;
            }
        }
    }

}
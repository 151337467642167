/* Module: S */
.quick-links {
	margin-top: pxToRem(-118);

	&__wrapper {
		@include sq-flex-box();
		padding: 0 pxToRem(10);
		max-width: pxToRem(1220);
		margin: 0 auto;
	}

	&__item {
		width: 33.33%;
		padding: 0 pxToRem(10);
		@include sq-flex-box();
		@include sq-flex-direction(column);

		&:first-child {
			.quick-links {
				&__title {
					background-color: rgba($sea, 0.9);
				}
			}
		}

		&:last-child {
			.quick-links {
				&__title {
					background-color: rgba($petrol, 0.9);
				}
			}
		}
	}

	&__content {
		display: block;
		background-color: $white;
		flex-grow: 1;
	}

	&__title {
		background-color: rgba($teal-blue, 0.9);
		min-height: pxToRem(118);
		height: pxToRem(118);
		overflow: hidden;

		> span {
			font-size: 1.875em; //30
			font-weight: normal;
			line-height: 1.2;
			padding: pxToRem(23) pxToRem(30);
			max-width: pxToRem(334);
			color: $white;
		}

		button {
			display: none;
		}
	}

	ul {
		padding: pxToRem(17) pxToRem(30) pxToRem(30) pxToRem(30);
	}
}
/* Module: S */
.search {
    &__wrapper {
        padding: pxToRem(15) 0 pxToRem(34) 0;

		form {
			padding: pxToRem(24) 0 0 0;
			@include placeholder-color($battleship-grey);

			&:after {
				display: none;
			}
		}

		input[type="text"] {
			height: pxToRem(70);
			font-size: 1em; //16
			line-height: pxToRem(70);
			padding: 0 pxToRem(164) 0 pxToRem(28);
		}

		input[type="submit"] {
			width: pxToRem(136);
			height: pxToRem(70);
			font-size: 1.250em; //20
	  		font-weight: bold;
			top: pxToRem(24);
			text-indent: 0;
			color: $white;
			@include font-smoothing;
			padding-right: pxToRem(8);
		}

		.search {
	    	&__partial-list {
	    		left: pxToRem(28);
	    		right: pxToRem(136);
	    	}
	    }
    }
}
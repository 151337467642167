/* Module: S */
.side-navigation {
    box-shadow: 0 pxToRem(2) pxToRem(10) pxToRem(4) rgba(199, 199, 199, 0.5);
    margin: 0 pxToRem(20);
    @include font-smoothing;

    li {
        padding: 0;
        margin: 0;
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }

    &__title {
        position: relative;
        background-color: $white;

        > span {
            display: block;
            font-size: 1em; //16
            font-weight: 500;
            color: $spruce;
            padding: pxToRem(14) pxToRem(20);   
        }

        button {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            padding: 0;
            background-color: transparent;
            border: none;
            display: block;
            @include clear-default-appearance;

        
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: pxToRem(26);
                background-color: $petrol;
                @include sq-transition((opacity .3s, transform .3s));   
            }

            &:before {
                width: pxToRem(2);
                height: pxToRem(12);
                margin-right: pxToRem(-1);
                margin-top: pxToRem(-6);
            }

            &:after {
                width: pxToRem(12);
                height: pxToRem(2);
                margin-right: pxToRem(-6);
                margin-top: pxToRem(-1);
            }
        }

        &.active {
            background-color: $spruce;

            > span {
                color: $white
            }

            button {
                &:before {
                    @include sq-transform(rotate(90deg));
                    background-color: $white;
                }

                &:after {
                    @include sq-transform(rotate(90deg));
                    opacity: 0;
                    background-color: $white;
                }
            }
        }
    }

    &__level-1 {
        padding: 0;
        margin: 0;
        list-style-type: none;
        @extend %clearfix;
        display: none;

        &.active {
            display: block;
        }

        >li {
            border-top: 2px solid $white-four;

            &:first-child {
                border-top: none;
            }
        }

        &-link {
            position: relative;
            background-color: $white;

            &:hover,
            &.current {
                background-color: $petrol;

                a {
                    color: $white;
                }

                button {
                    &:before,
                    &:after {
                        background-color: $white;
                    }
                }
            }

            a {
                font-size: 0.875em; //14
                font-weight: bold;
                line-height: 1.6;
                color: $pine-green-two;
                padding: pxToRem(14) pxToRem(50) pxToRem(14) pxToRem(20);
                display: block;
            }

            button {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: pxToRem(52);
                background-color: transparent;
                border: none;
            
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: pxToRem(26);
                    background-color: $petrol;
                    @include sq-transition((opacity .3s, transform .3s));   
                }

                &:before {
                    width: pxToRem(2);
                    height: pxToRem(12);
                    margin-right: pxToRem(-1);
                    margin-top: pxToRem(-6);
                }

                &:after {
                    width: pxToRem(12);
                    height: pxToRem(2);
                    margin-right: pxToRem(-6);
                    margin-top: pxToRem(-1);
                }
            }

            &.active {
                background-color: $petrol;

                a {
                    color: $white;
                }

                button {
                    &:before {
                        @include sq-transform(rotate(90deg));
                        background-color: $white;
                    }

                    &:after {
                        @include sq-transform(rotate(90deg));
                        opacity: 0;
                        background-color: $white;
                    }

                }
            }
        }
    }

    &__level-2 {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: none;

        &.active {
            display: block;
        }

        > li {
            border-top: 2px solid $white-four;

            &:first-child {
                border-top: none;
            }
        }

        &-link {
            position: relative;
            background-color: $white;

            &:hover,
            &.current {
                background-color: $ocean;

                a {
                    color: $white;
                }

                button {
                    &:before,
                    &:after {
                        background-color: $white;
                    }
                }
            }

            a {
                font-size: 0.875em; //14
                font-weight: 500;
                line-height: 1.6;
                color: $pine-green-two;
                padding: pxToRem(11) pxToRem(45) pxToRem(11) pxToRem(20);
                display: block;
            }

            button {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: pxToRem(52);
                background-color: transparent;
                border: none;
            
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: pxToRem(26);
                    background-color: $petrol;
                    @include sq-transition((opacity .3s, transform .3s));   
                }

                &:before {
                    width: pxToRem(2);
                    height: pxToRem(12);
                    margin-right: pxToRem(-1);
                    margin-top: pxToRem(-6);
                }

                &:after {
                    width: pxToRem(12);
                    height: pxToRem(2);
                    margin-right: pxToRem(-6);
                    margin-top: pxToRem(-1);
                }
            }

            &.active {
                background-color: $ocean;

                a {
                    color: $white;
                    font-weight: bold;
                }

                button {
                    &:before {
                        @include sq-transform(rotate(90deg));
                        background-color: $white;
                    }

                    &:after {
                        @include sq-transform(rotate(90deg));
                        opacity: 0;
                        background-color: $white;
                    }

                }
            }
        }
    }

    &__level-3 {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: none;

        &.active {
            display: block;
        }

        > li {
            border-top: 2px solid $white-four;

            &:first-child {
                border-top: none;
            }
        }

        &-link {
            position: relative;
            background-color: rgba($white, 0.5);

            &:hover,
            &.current {
                background-color: $white;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: pxToRem(2);
                    background-color: $ocean;
                }
            }

            a {
                font-size: 0.875em; //14
                font-weight: 500;
                line-height: 1.6;
                color: $petrol;
                padding: pxToRem(11) pxToRem(20);
                display: block;
            }

            button {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: pxToRem(52);
                background-color: transparent;
                border: none;
            
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: pxToRem(26);
                    background-color: $petrol;
                    @include sq-transition((opacity .3s, transform .3s));   
                }

                &:before {
                    width: pxToRem(2);
                    height: pxToRem(12);
                    margin-right: pxToRem(-1);
                    margin-top: pxToRem(-6);
                }

                &:after {
                    width: pxToRem(12);
                    height: pxToRem(2);
                    margin-right: pxToRem(-6);
                    margin-top: pxToRem(-1);
                }
            }

            &.active {
                background-color: $white;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: pxToRem(2);
                    background-color: $ocean;
                }

                a {
                    color: $ocean;
                    font-weight: bold;
                }

                button {
                    &:before {
                        @include sq-transform(rotate(90deg));
                        background-color: $ocean;
                    }

                    &:after {
                        @include sq-transform(rotate(90deg));
                        opacity: 0;
                        background-color: $ocean;
                    }

                }
            }
        }
    }

    &__level-4 {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: none;

        &.active {
            display: block;
        }

        > li {
            border-top: 2px solid $white-four;

            &:first-child {
                border-top: none;
            }
        }

        &-link {
            position: relative;
            background-color: rgba($white, 0.5);

            .current {
                a {
                    color: $ocean; 
                }
            }

            a {
                font-size: 0.875em; //14
                font-weight: 500;
                line-height: 1.6;
                color: $petrol;
                padding: pxToRem(11) pxToRem(30);
                display: block;

                &:hover {
                    color: $ocean; 
                }
            }

            button {
                position: absolute;
                top: 0;
                bottom: 0;
                right: pxToRem(10);
                width: pxToRem(52);
                background-color: transparent;
                border: none;
            
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: pxToRem(26);
                    background-color: $petrol;
                    @include sq-transition((opacity .3s, transform .3s));   
                }

                &:before {
                    width: pxToRem(2);
                    height: pxToRem(12);
                    margin-right: pxToRem(-1);
                    margin-top: pxToRem(-6);
                }

                &:after {
                    width: pxToRem(12);
                    height: pxToRem(2);
                    margin-right: pxToRem(-6);
                    margin-top: pxToRem(-1);
                }
            }

            &.active {
                a {
                    color: $ocean;
                    font-weight: bold;
                }

                button {
                    &:before {
                        @include sq-transform(rotate(90deg));
                        background-color: $ocean;
                    }

                    &:after {
                        @include sq-transform(rotate(90deg));
                        opacity: 0;
                        background-color: $ocean;
                    }

                }
            }
        }
    }

    &__level-5 {
        padding: 0 pxToRem(30) pxToRem(30) pxToRem(30);
        margin: 0;
        list-style-type: none;
        background-color: rgba($white, 0.5);
        display: none;

        &.active {
            display: block;
        }

        > li {
            border-top: 1px solid $white-four;
            background-color: $white;

            &:first-child {
                border-top: none;
            }
        }

        a {
            font-size: 0.875em; //14
            font-weight: 500;
            line-height: 1.6;
            color: $pine-green-three;
            padding: pxToRem(11) pxToRem(30);
            display: block;

            &:hover {
                color: $ocean;
            }
        }
    }

}
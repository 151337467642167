/* Module: S */
.block-tiles {
	&__title {
		font-size: 2.000em; //32
		line-height: 1.25;
	}

	&__text {
		font-size: 1em; //14
		line-height: 1.5;
		padding-top: pxToRem(34);
	}

	&__link {
		padding-top: pxToRem(34);
	}
}
/* Module: S */
.side-navigation {
    box-shadow: none;
    margin: 0;
    padding: pxToRem(25) 0 pxToRem(50) 0;

    &__title {
        background-color: transparent;

        > span {
            padding: pxToRem(14) 0;
        }

        button {
            display: none;
        }
    }

    &__level-1 {
        display: block;
    }
}
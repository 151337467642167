/* Module: S */
.share {
	position: absolute;
	bottom: 0;
	right: pxToRem(20);
	display: none;
	z-index: 1;

	&__item {
		border-left: 1px solid rgba($white-two, 0.2);
		float: left;
		@include font-smoothing;

		&:first-child {
			border-left: none;
		}
	}

	a {
		position: relative;
		color: $white-two;
		background-color: $petrol;
		text-decoration: none;
		padding: pxToRem(17) 0;
		width: pxToRem(110);
		text-align: center;
		font-size: 0.875em; //14
		line-height: 1.2;
		font-weight: 500;
		display: block;

		&:hover,
		&:focus {
			background-color: $ocean;
		}

		&.addthis_button {
			padding-right: pxToRem(20);

			&:after {
				content: url(../mysource_files/share.svg);
				position: absolute;
				top: pxToRem(15);
				right: pxToRem(22);
				width: pxToRem(15);
				height: pxToRem(20);
			}

		}

		&.print {
			padding-right: pxToRem(30);

			&:after {
				content: url(../mysource_files/print.svg);
				position: absolute;
				top: pxToRem(18);
				right: pxToRem(23);
				width: pxToRem(20);
				height: pxToRem(15);
			}

		}

	}

	&--top {
		bottom: auto;
		top: 0;
	}
}
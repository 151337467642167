.main {
	padding-bottom: pxToRem(64);
}

.section {
	&__wrapper {
        padding: 0 pxToRem(20);
	}

    &__header {
        padding: 0 0 pxToRem(30) 0;
    }

	&__row {
		display: flex;
		margin: 0 pxToRem(-10);

		&--float {
			display: block;

			.section {
				&__column {
					float: left;

					&--33 {
						float: right;
                        clear: right;
						max-width: pxToRem(320);
					}
				}
			}
		}
	}

	&__column {
		padding: 0 pxToRem(10);

		&--66 {
			width: 66.66%;
		}

		&--33 {
			width: 33.33%;
		}

		&--300 {
			width: pxToRem(300);
			min-width: pxToRem(300);
		}

		&--grow {
			> div {
				height: 100%;
			}
		}
	}

    &--wide-70 {
    	padding-top: pxToRem(70);
    	padding-bottom: pxToRem(70);
    }
}

.back {
    padding: pxToRem(80) 0 pxToRem(10) 0;

    &:before {
        top: pxToRem(48);
    }
}

.content {
	padding: pxToRem(30) 0;

    p {
        padding: pxToRem(15) 0;
    }

    blockquote {
        margin: pxToRem(20) 0;
    }

    img {
        &.full {
            margin: pxToRem(30) 0;
        }

        &.left,
        &.left-50,
        &.left-40,
        &.left-30 {
            margin: pxToRem(5) pxToRem(30) pxToRem(20) 0;
        }

        &.right,
        &.right-50,
        &.right-40,
        &.right-30 {
            margin: pxToRem(5) 0 pxToRem(20) pxToRem(30);
        }
    }

    p {
        &.full {
            padding: pxToRem(30) 0;
        }

        &.left,
        &.left-50,
        &.left-40,
        &.left-30 {
            padding: pxToRem(20) pxToRem(30) pxToRem(20) 0;
        }

        &.right,
        &.right-50,
        &.right-40,
        &.right-30 {
            padding: pxToRem(20) 0 pxToRem(20) pxToRem(30);
        }
    }

    table {
        th {
            padding: pxToRem(18) pxToRem(20);
        }

        td {
            padding: pxToRem(20);
        }
    }
}

.desktop-only {
    display: block;
}

.collapse-padding-top + .collapse-padding-top {
    padding-top: 0;
}

/*
--------------------
Modules
--------------------
*/
/* Module: S */
.sq-form {
	&-error {
		font-size: 0.750em; //12
		font-weight: 500;
		color: $scarlet;
		padding: pxToRem(10) 0 0 0;
		text-align: right;
		margin: 0;
	}

	&-question {
		@include placeholder-color($battleship-grey);
		padding: pxToRem(10) 0;
		max-width: pxToRem(579);

		label,
		legend {
			font-size: 0.875em; //14
			line-height: 1.44;
			font-weight: 500;
			color: $pine-green-four;
			padding: 0 0 pxToRem(10) 0;
			display: block;

		}

		abbr {
			border: none;
			text-decoration: none;
			color: $scarlet;
		}

		fieldset {
			padding: 0;
			margin: 0;
			border: none;
		}

		input[type="date"],
		input[type="text"],
		input[type="search"],
		input[type="email"],
		input[type="tel"],
		input[type="password"] {
			font-size: 0.875em; //14
			line-height: 1.44;
			font-weight: 500;
			color: $pine-green-four;
			padding: pxToRem(13) pxToRem(20);
			width: 100%;
			border: 2px solid $light-grey;
			background-color: transparent;

			&:disabled {
				opacity: 0.5;
			}

			&:focus {
				border-color: $light-grey-blue;
			}
		}

		textarea {
			font-size: 0.875em; //14
			line-height: 1.44;
			font-weight: 500;
			color: $pine-green-four;
			padding: pxToRem(13) pxToRem(20);
			width: 100%;
			border: 2px solid $light-grey;
			background-color: transparent;
			resize: none;

			&:disabled {
				opacity: 0.5;
			}

			&:focus {
				border-color: $light-grey-blue;
			}
		}

		input[type="radio"] {
			position: absolute;
			opacity: 0;

			&:focus {
				& + .ant-radio-inner {
					&:before {
						outline: auto 5px -webkit-focus-ring-color;
					}
				}
			}

			&:checked {
				& + label {
					&:before {
						border-color: $teal-blue;
					}

					&:after {
						content: '';
						width: 6px;
						height: 6px;
						border-radius: 100%;
						position: absolute;
						left: 5px;
						top: 50%;
						margin-top: -3px;
						background-color: $teal-blue;
					}
				}
			}

			&:disabled {
				& + label {
					&:before {
						opacity: 0.5;
					}

					&:after {
						opacity: 0.5;
					}
				}
			}

			& + label {
				display: block;
				position: relative;
				padding: pxToRem(4) 0 pxToRem(4) pxToRem(32);

				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					margin-top: -8px;
					width: 16px;
					height: 16px;
					border-radius: 100%;
					border: 2px solid $light-grey;
				}
			}
		}

		input[type="checkbox"] {
			position: absolute;
			opacity: 0;

			&:focus {
				& + .ant-radio-inner {
					&:before {
						outline: auto 5px -webkit-focus-ring-color;
					}
				}
			}

			&:checked {
				& + label {
					&:before {
						border-color: $teal-blue;
						background-color: $teal-blue;
					}

					&:after {
						content: url(../mysource_files/tick.svg);
						width: pxToRem(12);
						height: pxToRem(8);
						line-height: pxToRem(8);
						position: absolute;
						left: pxToRem(3);
						top: 50%;
						margin-top: pxToRem(-5);
					}
				}
			}

			&:disabled {
				& + label {
					&:before {
						opacity: 0.5;
					}

					&:after {
						opacity: 0.5;
					}
				}
			}

			& + label {
				display: block;
				position: relative;
				padding: pxToRem(4) 0 pxToRem(4) pxToRem(32);

				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					margin-top: pxToRem(-8);
					width: pxToRem(16);
					height: pxToRem(16);
					border-radius: pxToRem(2);
					border: 2px solid $light-grey;
				}
			}
		}

		&-select {
			.sq-form-question-answer {
				position: relative;	

				&:after {
		            content: url(../mysource_files/chevron-down.svg);
		            width: pxToRem(13);
		            height: pxToRem(13);
		            line-height: 7px;
		            display: block;
		            position: absolute;
		            top: 50%;
		            margin-top: pxToRem(-6);
		            right: pxToRem(21);
				}
			}

			select {
				font-size: 0.875em; //14
				line-height: 1.44;
				font-weight: 500;
				color: $pine-green-four;
				padding: pxToRem(13) pxToRem(46) pxToRem(13) pxToRem(20);
				width: 100%;
				border: 2px solid $light-grey;
				border-radius: 0;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				position: relative;
				z-index: 1;
				background-color: transparent;

				&::-ms-expand {
				    display: none; /* remove default arrow in IE 10 and 11 */
				}
			}
		}

		&-error {
			input[type="text"],
			input[type="search"],
			input[type="email"],
			input[type="tel"],
			input[type="password"] {
				border-color: $scarlet;

				&:focus {
					border-color: $scarlet;
				}
			}

		}

		&-option-list,
		&-tickbox-list {
			ul {
				list-style-type: none;
				padding: 0;
				margin: 0;
			}

			li {
				padding: 0;
				margin: 0;				
			}
		}
	}
}

.content {
	.sq-form {
		&-error {
			font-size: 0.750em; //12
			font-weight: 500;
			color: $scarlet;
			padding: pxToRem(10) 0 0 0;
			text-align: right;
			margin: 0;
		}

		&-question-option-list,
		&-question-tickbox-list {
			ul {
				list-style-type: none;
				padding: 0;
				margin: 0;
			}

			li {
				padding: 0;
				margin: 0;

				&:before {
					display: none;
				}				
			}
		}
	}
}

form {	
	input[type="submit"] {
	    background-color: $ocean;
	    text-decoration: none;
	    display: inline-block;
	    border: none;
	    border-radius: pxToRem(30);
	    padding: pxToRem(16) pxToRem(61) pxToRem(16) pxToRem(28);
	    margin-top: pxToRem(20);
	    position: relative;
	    font-size: 1rem; //16
	    font-weight: bold;
	    color: $white;
	    @include font-smoothing;
		@include clear-default-appearance;
		background-image: url(../mysource_files/arrow-long-right-teal-light.png);
		background-size: pxToRem(47);
		background-position: center right;
		background-repeat: no-repeat;

	    &:hover,
	    &:focus,
	    &:active {
	        text-decoration: none;
	        background-color: $petrol;
	    }

	    & + input[type="submit"] {
	    	margin-left: pxToRem(10);
	    }

	    &.invert {
	        color: $twilight-blue;
	        background-color: $white;
			background-image: url(../mysource_files/cancel.png);
			background-size: pxToRem(42);

		    &:hover,
		    &:focus,
		    &:active {
		        background-color: $light-grey;
		    }
	    }

	}

}

//https://jira.squiz.net/browse/LGNSWWBS-2
.sq-form-errors-message {
  color: $scarlet;
}

fieldset {
  border:  none;
  padding: 0;
	margin:  0;
	
	legend {
		color: $spruce;
		font-size: pxToRem(20);
		font-weight: 500;
		line-height: 1.2;
		margin: 0;
		padding: pxToRem(10) 0;
	}
}

.btn-wrap {
	display: inline-block;
	margin-right: pxToRem(20);

	&.btn-wrap--prev {
		input[type="submit"] {
			padding: pxToRem(16) pxToRem(28) pxToRem(16) pxToRem(61);
			background-image: url(../mysource_files/arrow-long-left-teal-light.png);
			background-size: pxToRem(47);
			background-position: 0 pxToRem(19);
			background-repeat: no-repeat;
		}
	}
}
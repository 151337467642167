/* Module: S */
.quick-links {
	&__content {
		display: block;
	}

	&__title {
		button {
			display: none;
		}
	}
}
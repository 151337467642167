/* Module: S */
.video-block {
	iframe {
		&.poster {
			opacity: 0;
			visibility: hidden;
		}
	}

	button {
		display: block;
	}
}
/* Module: S */
.block {
	padding: pxToRem(20) 0;

	&__container {
		padding: pxToRem(10) 0;
		
		.block {
			padding: pxToRem(10) 0;
		}

		&--attached {
			padding: 0;
			margin: pxToRem(-30) 0 pxToRem(-40) 0;
		}
	}

	&__wrapper {
		background-color: $white;
		padding: pxToRem(20) pxToRem(20) pxToRem(30) pxToRem(20);
	}

	p {
		color: $battleship-grey;
	}

	a.external-link {
		
		position: relative;
		margin-right: pxToRem(3);

		&:after {
			content: url(../mysource_files/icon-external-link.svg);
			width: pxToRem(13);
			height: pxToRem(13);
			display: inline-block;
			margin-left: pxToRem(6);
		}
	}

	ol,
	ul {
		li {
			color: $battleship-grey;
		}
	}

	&__more {
		position: absolute;
		bottom: pxToRem(20);
		left: 0;
		right: 0;
		height: pxToRem(186);
  		background-image: linear-gradient(to bottom, rgba($white, 0), $white);
  		display: none;

		button {
			position: absolute;
			bottom: pxToRem(22);
			right: pxToRem(20);
			border-radius: pxToRem(25);
			border: none;
			background-color: $ocean;
			color: $white-four;
			font-size: 0.875em; //14
			line-height: 1.44;
			font-weight: 500;
			@include font-smoothing;
			padding: pxToRem(15) pxToRem(25);
			min-width: pxToRem(140);
			text-align: left;
            
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: pxToRem(26);
                background-color: $greyblue;
                @include sq-transition((opacity .3s, transform .3s));   
            }

            &:before {
                width: pxToRem(2);
                height: pxToRem(12);
                margin-right: pxToRem(-1);
                margin-top: pxToRem(-6);
            }

            &:after {
                width: pxToRem(12);
                height: pxToRem(2);
                margin-right: pxToRem(-6);
                margin-top: pxToRem(-1);
            }


		}
	}

	&.collapsed {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			bottom: pxToRem(20);
			left: 0;
			right: 0;
			height: pxToRem(2);
			background-color: $teal-blue;
		}

		.block {
			&__wrapper {
				max-height: pxToRem(500);
				overflow: hidden;
			}

			&__more {
				display: block;
			}
		}
	}
}
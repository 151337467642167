/* Module: S */
.header {
	height: pxToRem(163);

    &__accessibility {
    	display: block;
        position: absolute;
        top: 0;
        left: pxToRem(190);
        height: pxToRem(19);

        div {
            font-size: 0.875em; //14
            font-weight: 500;
            color: $pine-green;

            a {
                opacity: 0;
                @include sq-transition(opacity .3s, top .3s); 
                text-decoration: none;
                position: absolute;
                top: pxToRem(-19);
	            padding: pxToRem(11) 0 pxToRem(10) pxToRem(40);
	            min-width: pxToRem(170);
	            background-color: $white-four;
	            border-top-right-radius: pxToRem(100);
	            border-bottom-right-radius: pxToRem(100);
	            outline: none;

                &:focus {
                    opacity: 1;
                	top: 0;
                }
            }

            span {
                opacity: 0;
                @include sq-transition(visibility .3s); 
                position: absolute;
                left: 0;
                right: 0;
                display: block;
	            padding: pxToRem(11) 0 pxToRem(10) pxToRem(40);
	            min-width: pxToRem(286);
	            background-color: $white-four;
	            border-top-right-radius: pxToRem(100);
	            border-bottom-right-radius: pxToRem(100);

                &.active {
                    opacity: 1;
                }
            }

        }
    }

	&__toggle {
		display: none;
	}

    &__title {
		width: pxToRem(212);
		padding: 0;
		background-color: transparent;
		position: relative;

		a {
			font-weight: bold;	
			padding: pxToRem(9) 0;
			border-top-right-radius: pxToRem(100);
			border-bottom-right-radius: pxToRem(100);	
			background-color: $ocean;
			display: block;
			text-align: center;

			&:hover,
			&:focus {
				background-color: $petrol;
			}
		}
    }

	&__navigation {
		float: left;
	}

	&__logo {
		clear: left;
		padding: pxToRem(27) pxToRem(26) pxToRem(35) pxToRem(26);

		img {
			max-height: pxToRem(61);
		}
	}

	&__search {
		top: pxToRem(40);
		padding: pxToRem(25) pxToRem(20);
		box-shadow: none;

		form {
			max-width: pxToRem(780);
			margin: 0 auto;

			&:after {
				bottom: pxToRem(9);
                width: pxToRem(24);
                height: pxToRem(24);
                line-height: pxToRem(24);
			}
		}

		input[type="text"] {
            font-size: 1.500em; //24
			padding: pxToRem(12) pxToRem(44) pxToRem(8) 0;
		}


		&-toggle {
			z-index: 10;

			button {
				background-color: $ocean;
				border-top-left-radius: pxToRem(100);
				border-bottom-left-radius: pxToRem(100);
				width: auto;
				height: pxToRem(40);
				padding: 0 pxToRem(34) 0 0;
				text-align: center;
				width: pxToRem(130);

				&:hover,
				&:focus {
					background-color: $petrol;
				}

                &:after {
                    width: pxToRem(19);
                    height: pxToRem(19);
                    line-height: pxToRem(19);
                    right: pxToRem(24);
                }

                > span {
                	text-indent: 0;
                }

                &.active {
                	&:before,
	                &:after {
	                    content: '';
	                    width: pxToRem(16);
	                    height: pxToRem(2);
	                    background-color: $white;
	                    border-radius: pxToRem(2);
	                    position: absolute;
	                    top: 50%;
	                    margin-top: pxToRem(-1);
	                    right: pxToRem(26);
	                }
	            }
			}
		}
	}
}
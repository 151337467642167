/* Module: S */
.licence-calculator {
	@extend %clearfix;
	padding: 0 pxToRem(20);

	button {
		@include clear-default-appearance();
		border: none;
		background-color: transparent;
	}

	.ant-alert {
		padding: 0 pxToRem(20) pxToRem(20) pxToRem(20);
        color: $pine-green;
        text-align: left;

        i {
        	display: none;
        }

        &.ant-alert-with-description {
			.ant-alert-message {
		        font-size: 1em; //16
		        line-height: 1.43;
			}
        }

        &.ant-alert-warning {
        	padding-bottom: pxToRem(40);
        }

        &.ant-alert-out {
        	background-color: $white-four;
			padding: pxToRem(20) 0;

			.ant-alert-message {
				> span {
					display: inline-block;
					width: pxToRem(19);
					height: pxToRem(19);
					border-radius: 100%;
					background-color: $pine-green;
					color: $white;
					font-size: pxToRem(12);
					line-height: pxToRem(21);
					text-align: center;
					margin-right: pxToRem(10);
					font-weight: normal;
					overflow: hidden;
					position: relative;
					top: pxToRem(3);
				}
			}
        }
	}

	.ant-alert-message {
        font-size: 0.875em; //14
        line-height: 1.43;
        font-weight: bold;
        display: block;
	}

	.ant-alert-description {
        font-size: 0.875em; //14
        line-height: 1.43;
        font-weight: 500;
        display: block;
        padding-top: pxToRem(5);
	}

	.ant-form-item {
		&:first-child {
			h3 {
				padding-top: pxToRem(18);
			}

			.ant-btn-edit {
				top: pxToRem(6);
			}
		}

		h3 {
			padding: pxToRem(50) pxToRem(40) pxToRem(18) 0;
		}
	}

	.ant-form-item-children {
		position: relative;
		display: block;

		.licence-calculator__section > label,
		> label {
			display: block;
			background-color: $white;
			border-top: pxToRem(2) solid $teal-blue;
			padding: pxToRem(30) pxToRem(20);
		}

		p {
			margin: 0;

			& + button {
				&:disabled {
					display: none;
				}
				
			}
		}
	}

	h3 {
		text-align: left;
		background-color: $white-four;
	}

	h4 {
		font-size: 1em;
		line-height: 1.5;
		font-weight: bold;
		color: $battleship-grey;
		padding: 0 0 pxToRem(20) 0;
		@include font-smoothing;
		text-align: left;
	}

	.ant-radio-wrapper {
		display: flex;
		width: 100%;
		text-align: left;

		.ant-radio {
			display: block;
			position: relative;
		}

		> span {
			&:last-child {
				display: block;
		        font-size: 0.875em; //14
		        line-height: 1.43;
		        font-weight: 500;
		        color: $battleship-grey;
				padding: pxToRem(13) 0;
			}
		}
	}

	.ant-form-item-control-wrapper {
		background-color: $white;
		text-align: center;
	}

	.ant-btn-edit {
		position: absolute;
		right: pxToRem(-20);
		top: pxToRem(38);
		display: block;
		height: pxToRem(50);
		font-size: 0.875em; //14
		line-height: pxToRem(50);
		color: $battleship-grey;
		padding: 0 pxToRem(20);
	}

	.ant-btn-primary {
	    background-color: $ocean;
	    text-decoration: none;
	    display: inline-block;
	    border: none;
	    border-radius: pxToRem(30);
	    padding: pxToRem(16) pxToRem(61) pxToRem(16) pxToRem(28);
	    position: relative;
	    font-size: 1rem; //16
	    font-weight: bold;
	    color: $white;
	    @include font-smoothing;
	    margin-bottom: pxToRem(30);

	    &:disabled {
	    	opacity: 0.5;
	    }

	    &:hover,
	    &:focus {
	        text-decoration: none;

	        &:after {
	            right: pxToRem(17);
	        }
	    }

	    &:active {
	        background-color: $petrol;

	        &:after {
	            right: pxToRem(17);
	            content: url(../mysource_files/arrow-long-right-white.svg);
	        }
	    }

	    &:after {
	        content: url(../mysource_files/arrow-long-right-teal-light.svg);
	        width: pxToRem(25);
	        height: pxToRem(16);
	        line-height: pxToRem(16);
	        display: block;
	        position: absolute;
	        top: 50%;
	        margin-top: pxToRem(-8);
	        right: pxToRem(22);
	        @include sq-transition(right 0.3s);
	    }

	    i {
	    	display: none;
	    }
	}

	input[type="radio"] {
		position: absolute;
		opacity: 0;

		&:focus {
			& + .ant-radio-inner {
				&:before {
					outline: auto 5px -webkit-focus-ring-color;
				}
			}
		}

		&:checked {
			& + .ant-radio-inner {
				&:before {
					border-color: $teal-blue;
				}

				&:after {
					content: '';
					width: pxToRem(8);
					height: pxToRem(8);
					border-radius: 100%;
					position: absolute;
					left: pxToRem(8);
					top: 50%;
					margin-top: pxToRem(-4);
					background-color: $teal-blue;
				}
			}
		}

		&:disabled {
			& + .ant-radio-inner {
				&:before {
					opacity: 0.5;
				}

				&:after {
					opacity: 0.5;
				}
			}
		}

		& + .ant-radio-inner {
			display: block;
			width: pxToRem(40);
			height: pxToRem(46);

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: pxToRem(-12);
				width: pxToRem(24);
				height: pxToRem(24);
				border-radius: pxToRem(3);
				border: 2px solid $light-grey;
			}
		}
	}

	&__section {
		position: relative;
	}

	&__footer {
		color: $white;
		background-color: $white-four;
		@include font-smoothing;
		padding-top: pxToRem(66);

		&-total {
			background-color: $ocean;
			padding: pxToRem(28) pxToRem(30);
			position: relative;
		}

		&-note {
			background-color: $petrol;
			padding: pxToRem(28) pxToRem(30);
		}

		&-form {
			background-color: $teal-blue;
			background-image: linear-gradient(97deg, $nice-blue, $teal-blue);
			padding: pxToRem(28) pxToRem(30);
			position: relative;
			@extend %clearfix;
			@include placeholder-color($twilight-blue);
		}

		&-fee {
			position: absolute;
			top: pxToRem(25);
			right: pxToRem(30);
			font-size: 1.875em; //30
			font-weight: 500;
			line-height: 1;
		}

		&-finalise-buttons {
			width: 100%;
			padding: pxToRem(30) 0;
			
			button.ant-btn-primary {
				margin-top: 0;
				margin-right: pxToRem(15);

				&:last-child { 
					margin-right: 0;
				}
			}
		}

		p {
			margin: 0;
			font-size: 0.875em; //14
			line-height: 1.57;
			text-align: left;
		}

		label {
			font-size: 1.250em; //20
			font-weight: bold;
			padding: pxToRem(13) 0;
			float: left;
		}

		input[type="text"],
		input[type="email"] {
			font-size: 1em; //16
			line-height: 1.4;
			font-weight: 500;
			color: $twilight-blue;
			padding: pxToRem(16) pxToRem(77) pxToRem(16) pxToRem(20);
			width: 100%;
			border: none;
			background-color: $white;
			float: right;
			border-radius: pxToRem(30);
			margin-right: pxToRem(2);
		}

		input[type="submit"] {
			position: absolute;
			text-indent: -9999px;
			width: pxToRem(57);
			height: pxToRem(54);
			bottom: pxToRem(28);
			right: pxToRem(30);
			padding: 0;
			margin: 0;
			border-radius: pxToRem(30);
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			background-color: $deep-sea-blue;
			background-image: url(../mysource_files/arrow-long-right-white.png);
			background-size: pxToRem(21);
			background-position: center;
		}

		.licence-calculator__footer-total {
			h3 {
				background-color: transparent;
				padding: 0 0 pxToRem(10) 0;
			}
		}
	}

	&__header {
		background-color: $teal-blue;
		background-image: linear-gradient(99deg, $nice-blue, $teal-blue);
		padding: pxToRem(16) pxToRem(20);
		@extend %clearfix;
		@include font-smoothing;

		h3 {
			font-size: 0.875em; //14
			font-weight: 500;
			line-height: 1.3;
			letter-spacing: 1px;
			padding: 0;
			text-transform: uppercase;
			float: left;
			color: $white;
			background-color: transparent;
		}

		button {
			display: block;
			border: none;
			background-color: transparent;
			float: right;
			font-size: 0.875em; //14
			font-weight: 500;
			line-height: 1.3;
			color: $white;
			padding: 0 pxToRem(30) 0 0;
			position: relative;

            &:after {
                content: url(../mysource_files/reset.svg);
                width: pxToRem(19);
                height: pxToRem(18);
                line-height: 18px;
                display: block;
                position: absolute;
                top: 50%;
                margin-top: pxToRem(-9);
                right: 0;
            }
		}
	}

	&__cost {
		padding: pxToRem(44) 0 pxToRem(60) 0;
		dl {
			margin: 0;
			@extend %clearfix;
		}

		dt {
			float: left;
			font-size: 1.250em; //20
			font-weight: 500;
			line-height: 1.8;
		}

		dd {
			margin: 0;
			float: right;
			font-size: 1.875em; //30
			font-weight: 500;
			line-height: 1.2;
		}

		p {
			font-size: 0.875em; //14
			line-height: 1.43;
			padding: pxToRem(13) 0 0 0;
			margin: 0;
		}
	}

	&__fees {
		background-color: $white;
		padding: pxToRem(24) pxToRem(20);
		color: $pine-green;

		.ant-alert-message {
			font-size: 12px; //12
			font-weight: 500;
			color: $pine-green;

			> span {
				display: inline-block;
				width: pxToRem(19);
				height: pxToRem(19);
				border-radius: 100%;
				background-color: $pine-green;
				color: $white;
				font-size: pxToRem(12);
				line-height: pxToRem(21);
				text-align: center;
				margin-right: pxToRem(10);
				font-weight: normal;
				overflow: hidden;
				position: relative;
				top: pxToRem(3);
			}
		}

		.ant-alert {
			&.ant-alert-warning {
				padding: pxToRem(9) pxToRem(10);
				margin-bottom: pxToRem(2);
				background-color: $white-four;
			}
		}

		.ant-alert-description {
			display: none;
		}

		h4 {
			font-size: 0.875em; //14
			font-weight: normal;
			line-height: 1.43;
			color: $battleship-grey;
			padding: pxToRem(12) 0 pxToRem(5);
		}
	}

	&__total {
		background-color: $ocean;
		@include font-smoothing;
		padding: pxToRem(24) pxToRem(20);
		color: $white;
	}

	&__note {
		background-color: $white-four;
		font-size: 1em; //16
		line-height: 1.5;
		color: $battleship-grey-two;
		margin: pxToRem(-10) 0 0 0;
		padding: 0 0 pxToRem(20) 0;
		text-align: left;
	}

	&__info {
		padding-bottom: pxToRem(20);
		text-align: left;

		p {
			font-size: 0.875em; //14
			line-height: 1.57;
			color: $battleship-grey;
			margin: 0;
			padding: pxToRem(10) 0;

			&:first-child {
				padding-top: 0;
			}

			&:last-child {
				padding-bottom: 0;
			}
		}

	}

	&__summary {
		text-align: left;

		p {
			border-top: pxToRem(2) solid $teal-blue;
			padding: pxToRem(15) pxToRem(65) pxToRem(15) pxToRem(20);
			margin: 0;
			font-size: 0.875em; //14
			font-weight: 500;
			line-height: 1.44;
		}

		& + label {
			border-top: none !important;
			padding-top: 0 !important;	
		}

		& + .licence-calculator__summary {
			p {
				border-top: none;
				padding-top: 0;
			}
		}
	}
}
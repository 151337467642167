/* Module: S */
.stripe {
	padding: pxToRem(10) 0;

	&__wrapper {
		background-color: $teal-blue;
		background-image: linear-gradient(98deg, $nice-blue, $teal-blue);
		padding: pxToRem(30) pxToRem(28) pxToRem(40) pxToRem(28);
	}

	&__intro {
		font-size: 0.875em; //14
		font-weight: bold;
		line-height: 2.14;
		color: $white;
		padding-bottom: pxToRem(30);
		position: relative;
		@include font-smoothing;

		&:after {
			content: '';
			position: absolute;
			bottom: pxToRem(22);
			height: pxToRem(2);
			left: 0;
			width: pxToRem(40);
			background-color: $greyblue;
		}
	}

	&__title {
		font-size: 1.750em; //28
		font-weight: 500;
		color: $white;
		@include font-smoothing;
	}

	&__button {
		padding-top: pxToRem(23);
	}

	&--tile {
		padding: pxToRem(20);
	}
}
/**
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * updated: {{date}}
 *
 * Table of Contents
 *
 * - Reset
 * - Utilities
 * - General
 * - Content
 * - Modules
    {{toc}}
 */

// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of
// a design cutup.
@import "boilerplate.scss";

/*
--------------------
Reset (normalize.css)
--------------------
*/

@import "normalize.scss";

// This import disabled by default due to rendering issues, particularly with IE where some text doesn't render at all
//@import "normalize-opentype.scss";

// Box size all the things
// https://squizmap.squiz.net/imp-bp/6400
// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// == Start box-sizing reset ==
*, *:before, *:after {
    -webkit-box-sizing: inherit;
       -moz-box-sizing: inherit;
            box-sizing: inherit;
}
html {
    @include sq-border-box;
}
// == End box-sizing reset ==

/*
--------------------
Utilities
--------------------
*/

@import "utilities.scss";

/*
--------------------
General
--------------------
*/

// Taken from HTML 5 BP
::-moz-selection {
    background: $content-selection-highlight;
    text-shadow: none;
}

::selection {
    background: $content-selection-highlight;
    text-shadow: none;
}

html,
body {
    height: 100%;
}
body {
    overflow-y: scroll;
    font-size: $base-font-scale + em;
    font-family: $base-font-family;
    line-height: 1.4;
    padding: 0;
    margin: 0;
    background-color: $white-four;
    color: $pine-green;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
    border: 0;
}

a {
    color: $pine-green;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

// Reset fonts for relevant elements (taken from bootstrap)
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport{
    width: device-width;
}

// Print style that shouldn't belong to print.scss to avoid the css beautifier from killing
// the @ syntax inside media queries
@page {
    margin: 0.5cm;
}

h1 {
    font-size: 1.750em; //28
    font-weight: 500;
    line-height: 1.07;
    margin: 0;
    padding: pxToRem(20) 0;
}

h2 {
    font-size: 1.625em; //26
    font-weight: 500;
    line-height: 1.23;
    margin: 0;
    padding: pxToRem(18) 0 pxToRem(28) 0;
    position: relative;
    clear: both;

    &:after {
        content: '';
        width: pxToRem(40);
        height: pxToRem(2);
        background-color: $teal-blue;
        display: block;
        position: absolute;
        bottom: pxToRem(16);
        left: 0;
    }
}

h3 {
    font-size: 1.375em; //22
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
    padding: pxToRem(18) 0;
}

h4 {
    font-size: 1.250em; //20
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
    padding: pxToRem(10) 0;
}

h5 {
    font-size: 1em; //16
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
    padding: pxToRem(10) 0;
}

.button {
    background-color: $ocean;
    text-decoration: none;
    display: inline-block;
    border: none;
    border-radius: pxToRem(30);
    padding: pxToRem(16) pxToRem(61) pxToRem(16) pxToRem(28);
    position: relative;
    font-size: 1rem; //16
    font-weight: bold;
    color: $white;
    @include font-smoothing;

    &:hover,
    &:focus {
        text-decoration: none;

        &:after {
            right: pxToRem(17);
        }
    }

    &:active {
        background-color: $petrol;

        &:after {
            right: pxToRem(17);
            content: url(../mysource_files/arrow-long-right-white.svg);
        }
    }

    &:after {
        content: url(../mysource_files/arrow-long-right-teal-light.svg);
        width: pxToRem(25);
        height: pxToRem(16);
        line-height: pxToRem(16);
        display: block;
        position: absolute;
        top: 50%;
        margin-top: pxToRem(-8);
        right: pxToRem(22);
        @include sq-transition(right 0.3s);
    }

    &--inverse {
        color: $twilight-blue;
        background-color: $white;

        &:active {
            background-color: $white-four;

            &:after {
                right: pxToRem(17);
                content: url(../mysource_files/arrow-long-right-teal-dark.svg);
            }
        }

    }
}

.button.external-link {
    &:after {
        content: url(../mysource_files/icon-external-link-white.svg);
        width: pxToRem(17);
        height: pxToRem(17);
        display: block;
        position: absolute;
        top: 50%;
    }
}

.button--inverse.external-link {
    &:after {
        content: url(../mysource_files/icon-external-link.svg);
    }
}

.back {
    padding: pxToRem(40) 0 pxToRem(10) 0;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: pxToRem(20);
        left: 0;
        right: 0;
        height: pxToRem(2);
        background-color: $teal-blue;
    }

    a {
        font-size: 1em; //16
        line-height: 1.2;
        font-weight: bold;
        color: $twilight-blue;
        display: inline-block;
        position: relative;
        padding-left: pxToRem(40);
        text-decoration: none;

        &:before {
            content: url(../mysource_files/arrow-long-right-teal-light.svg);
            width: pxToRem(25);
            height: pxToRem(16);
            line-height: pxToRem(16);
            display: block;
            position: absolute;
            top: 50%;
            margin-top: pxToRem(-8);
            left: 0;
            @include sq-transform(rotate(180deg));
            @include sq-transition(left 0.3s);
        }

        &:hover,
        &:focus {
            &:before {
                left: pxToRem(-5);
            }
        }
    }
}

.section {
    position: relative;

    &__row {
        &--float {
            @extend %clearfix;
        }
    }

    &__wrapper {
        max-width: pxToRem(1220);
        margin: 0 auto;
        position: relative;
    }

    &__header {
        @extend %clearfix;
        padding: pxToRem(45) pxToRem(20) pxToRem(30) pxToRem(20);
    }

    &__title {
        font-size: 1.125em; //18
        font-weight: 500;
        float: left;
    }

    &__link {
        float: right;

        a {
            font-size: 1em; //16
            font-weight: 500;   
            line-height: 1.6;
            display: inline-block;  
            text-decoration: none;
            position: relative;
            padding-right: pxToRem(38);

            &:after {
                content: url(../mysource_files/arrow-long-right-teal-light.svg);
                width: pxToRem(25);
                height: pxToRem(16);
                line-height: pxToRem(16);
                display: block;
                position: absolute;
                top: 50%;
                margin-top: pxToRem(-8);
                right: 0;
                @include sq-transition(right 0.3s);
            }

            &:hover,
            &:focus {
                &:after {
                    right: pxToRem(-5);
                }
            }       
        }
    }

    &--white {
        background-color: $white;
    }

    &--bottom-55 {
        padding-bottom: pxToRem(55);
    }
}


/*
--------------------
Content
--------------------
*/

.content {
    padding: pxToRem(20) pxToRem(20) pxToRem(40) pxToRem(20);
    @extend %clearfix;

    h3,
    h4,
    h5 {
        color: $spruce;
    }

    p {
        margin: 0;
        padding: pxToRem(10) 0;
        font-size: 1em; //16
        line-height: 1.5;
    }

    p,
    ol,
    ul,
    blockquote,
    table {
        a {
            
            text-decoration: underline;
            font-weight: bold;

            &:hover,
            &:focus {
                text-decoration: none;
            }

            &.button {
                text-decoration: none;
            }
        }
    }


    p {
        a.external-link:not(.button) {
            position: relative;
        
            &:after {
              content: url(../mysource_files/icon-external-link.svg);
              display: inline-block;
              height: 13px;
              width: 13px;
              margin-left: 5px;
              margin-right: 3px;
              top: 0;
            }
        }
        
    }

    a {

        &.highlighted { 
            font-weight: bold;
            border-width: 0 0 2px 0;
            border-style: solid;
            border-color: $greyblue;
            border-image-source: url(../mysource_files/border.png);
            border-image-slice: 33% 33%;
            border-image-repeat: round;
            text-decoration: none;

            &:hover,
            &:focus {
                color: $teal-blue;
            }
        }

        &.link {
            display: inline-block;
            padding-right: pxToRem(39);
            position: relative;
            font-weight: 500;
            color: $pine-green;
            text-decoration: none;

            &:after {
                content: url(../mysource_files/arrow-long-right-teal-light.svg);
                width: pxToRem(25);
                height: pxToRem(16);
                line-height: pxToRem(16);
                display: block;
                position: absolute;
                top: 50%;
                margin-top: pxToRem(-8);
                right: pxToRem(5);
                @include sq-transition(right 0.3s);
            }

            &:hover,
            &:focus {
                text-decoration: none;
                color: $pine-green;

                &:after {
                    right: 0;
                }
            }
        }
    }

    blockquote {
        padding: pxToRem(30) pxToRem(20) pxToRem(30) pxToRem(24);
        margin: pxToRem(20) 0;
        position: relative;
        color: $battleship-grey;
        background-color: $white;
        font-size: 0.875em; //14
        line-height: 1.71;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: pxToRem(4);
            background-color: $teal-blue;
        }

        a.external-link {
            
            margin-right: pxToRem(3);

            &:after {
                content: url(../mysource_files/icon-external-link.svg);
                width: pxToRem(13);
                height: pxToRem(13);
                display: inline-block;
                margin-left: pxToRem(4);
            }
        }
    }

    .note {
        color: $battleship-grey-two;
    }

    ul,
    ol {
        li {
            a.external-link {
            
                margin-right: pxToRem(3);
    
                &:after {
                    content: url(../mysource_files/icon-external-link.svg);
                    width: pxToRem(13);
                    height: pxToRem(13);
                    display: inline-block;
                    margin-left: pxToRem(5);
                }
            }
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            padding: pxToRem(10) 0 pxToRem(10) pxToRem(34);
            margin: 0;
            font-size: 1em;
            line-height: 1.5;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: pxToRem(22);
                width: pxToRem(20);
                height: pxToRem(2);
                background-color: $teal-blue;
            }
        }

        ul {
            padding-top: pxToRem(10);
            padding-left: pxToRem(10);
            margin-bottom: pxToRem(-10);
        }

        &.links {
            li {
                &:before {
                    background-color: $greyblue;
                }
            }

            a {
                font-weight: bold;
                border-width: 0 0 2px 0;
                border-style: solid;
                border-color: $greyblue;
                border-image-source: url(../mysource_files/border.png);
                border-image-slice: 33% 33%;
                border-image-repeat: round;
                text-decoration: none;

                &:hover,
                &:focus {
                    color: $teal-blue;
                }
            }
        }
    }

    ol {
        padding: 0 0 0 pxToRem(17);
        margin: 0;

        li {
            padding: pxToRem(10) 0 pxToRem(10) pxToRem(17);
            margin: 0;
            font-size: 1em;
            line-height: 1.5;
            position: relative;
        }

        ol {
            padding-top: pxToRem(10);
            margin-left: pxToRem(10);
            margin-bottom: pxToRem(-10);
            list-style-type: lower-alpha;

            ol {
                list-style-type: lower-roman;
            }
        }

        &.alpha {
            list-style-type: lower-alpha;
        }

        &.upper-alpha {
            list-style-type: upper-alpha;
        }

        &.roman {
            list-style-type: lower-roman;
        }

        &.upper-roman {
            list-style-type: upper-roman;
        }
    }

    img {
        &.full,
        &.left-50,
        &.left-40,
        &.left-30,
        &.right-50,
        &.right-40,
        &.right-30 {
            width: 100%;
            margin: pxToRem(5) 0 pxToRem(20) 0;
        }
    }

    p {
        &.full,
        &.left-50,
        &.left-40,
        &.left-30,
        &.right-50,
        &.right-40,
        &.right-30 {
            padding: pxToRem(20) 0;

            img {
                width: 100%;
            }
        }
    }
    
    table {
        width: 100%;
        margin: pxToRem(10) 0;
        background-color: $white;

        th {
            background-color: $teal-blue;
            background-image: linear-gradient(103deg, $nice-blue, $teal-blue);
            color: $white;
            font-size: 1.063em; //17
            font-weight: bold;
            @include font-smoothing;
            padding: pxToRem(20) pxToRem(10);
            line-height: 1.4;
            text-align: left;
            border-left: 2px solid $white;

            &:first-child {
                border-left: none;
            }
        }

        td {
            border: 2px solid $white-four;
            font-size: 0.875em;//14
            line-height: 1.71;
            padding: pxToRem(13) pxToRem(10);
        }

        th,
        td {
            vertical-align: top;

            p {
                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }

    .table {
        min-width: pxToRem(500);

        &__wrapper {
            position: relative;

            &.scrolling {
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    background: linear-gradient(to right, rgba($white, 0) 0%, $white 100%);
                    width: pxToRem(100);
                }
            }

        }

        &__inner {
            overflow-x: scroll;
            position: relative;
        }
    }

    .landing-tiles {
        padding-left: 0;
        padding-right: 0;
    }
}

.desktop-only {
    display: none;
}

/*
--------------------
Modules
--------------------
*/
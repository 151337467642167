/* Module: S */
.block {
	padding: pxToRem(30) 0;

	&__container {
		padding: pxToRem(40) 0 0 0;
		
		.block {
			padding: pxToRem(10) 0;
		}

		&--attached {
			padding: 0;
			margin: pxToRem(-30) 0 pxToRem(-40) 0;
		}
	}

	&__wrapper {
		padding: pxToRem(20) pxToRem(30) pxToRem(30) pxToRem(30);
	}

	&__more {
		button {
			bottom: pxToRem(26);
			right: pxToRem(24);
		}
	}

	&.collapsed {
		.block {
			&__wrapper {
				max-height: pxToRem(394);
			}
		}
	}
}
/* Module: S */
.footer {
	padding: pxToRem(27) pxToRem(40);

	&__logo {
		padding: 0;
		float: left;
	}

	ul {
		padding: pxToRem(18) 0 pxToRem(14) 0;
		float: right;
	}

	li {
		padding: 0 pxToRem(21);
		float: left;

		&:last-child {
			padding-right: 0;
		}
	}
}
//Colours

$black: #000;


$white: #fff;
$white-two: #fefefe;
$white-three: #eceae9;
$white-four: #f7f6f6;
$white-five: #fdfdfd;
$white-six: #e8e8e8;

$light-grey: #dbd8d6;
$light-grey-blue: #b1b7b8;
$battleship-grey: #6b787b;
$battleship-grey-two: #5b686b;
$grey: #f1f0f0;
$dark-grey: #2B383A;
$greyish-white: #e5e5e5;
$grey-light: #E3E3E3;

$evergreen: #06232c;
$spruce: #0b3744;
$ocean: #006e88;
$pine-green: #09303b;
$pine-green-two: #0c3845;
$pine-green-three: #0d3946;
$pine-green-four: #082a33;
$nice-blue: #1594a8;
$teal-blue: #007a90; // changed from #0087a0 for accessibility [text colour #f7f6f6]
$teal-blue-two: #078aa2;
$petrol: #005671;
$sea: #3299a9;
$greyblue: #64b1bb;
$deep-teal: #014960;
$twilight-blue: #0a4151;
$dark-slate-blue: #0f3a47;
$deep-sea-blue: #00617c;
$blue-green: #108ea2;
$scarlet: #d0021b;

// Base font
$base-font-scale: 1; // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: $pine-green;
$base-font-family: 'Roboto',
sans-serif;

// Selection highlighting
$content-selection-highlight: #b3d4fc;
/* Module: S */
.landing-tiles {
	padding: 0 pxToRem(10) pxToRem(32) pxToRem(10);

	& + .landing-tiles {
		margin-top: pxToRem(-62);
	}

	&__intro {
		padding-top: pxToRem(35);

		&:after {
			top: pxToRem(1);
		}
	}

	&__wrapper {
		padding-top: pxToRem(50);
		@include sq-flex-box();
		@include sq-flex-wrap(wrap);

		&:first-child {
			padding-top: pxToRem(30);
		}
	}

	&__item {
		padding: pxToRem(10);
		width: 50%;
	}

	a {
		padding: pxToRem(53) pxToRem(40) pxToRem(64) pxToRem(40);
		min-height: pxToRem(268);
		height: 100%;

	    &:before,
	    &:after {
	        bottom: pxToRem(32);
	        left: pxToRem(40);
	    }

		&:hover,
		&:focus {
			&:before,
			&:after {
	        	left: pxToRem(56);
			}
		}
	}

	&__title {
		font-size: 1.375em; //22
		line-height: 1.2;
	}

	&__text {
		font-size: 1em; //16
		line-height: 1.5;
		padding-top: pxToRem(20);

		p {
			padding: 0;
			font-size: pxToRem(16); //16
			line-height: 1.5;
		}
	}
}
/* Module: S */
.tabs {
	border: none;

	&__controls {
		display: block;
		@include sq-flex-box();
		margin: 0 -1px;
		overflow: hidden;
	}

	&__panels {
		.tabs {
			&__title {
				display: none;
			}
		}
	}

	&__item {
		border-top: none;
	}

	&__title {
		width: 100%;
		margin-left: 1px;
		margin-right: 1px;

		button {
		    width: 100%;
		    height: 100%;
		    padding: pxToRem(20);
	        
	        &:before,
	        &:after {
	            display: none; 
	        }
		}
	}
}
/* Module: S */
.banner {
	min-height: pxToRem(50);
	height: auto;
	background-image: none!important;

	.search {
		display: none;
	}

	&--image {
		h1 {
			color: $white;
		}
	}
}
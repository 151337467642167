/* Module: S */
.block-tiles {
	margin: pxToRem(-20) 0;
	padding: 0 pxToRem(20);

	&__item {
		padding: pxToRem(30) pxToRem(20) pxToRem(48) pxToRem(20);
		margin: pxToRem(20) 0;
		background-color: $white;
	}


	&__title {
		font-size: 1.625em; //26
		line-height: 1.15;
	}

	&__text {
		font-size: 0.875em; //14
		line-height: 1.71;
		color: $battleship-grey;
		padding-top: pxToRem(26);
	}

	&__link {
		padding-top: pxToRem(26);

		a {
			padding-right: pxToRem(34);
			display: inline-block;
			position: relative;
			text-decoration: none;
			font-size: 1em;
			font-weight: 500;

		    &:after {
		        content: url(../mysource_files/arrow-long-right-teal-light.svg);
		        width: pxToRem(25);
		        height: pxToRem(16);
		        line-height: pxToRem(16);
		        display: block;
		        position: absolute;
		        bottom: 50%;
		        margin-bottom: pxToRem(-10);
		        right: 0;
		        @include sq-transition(right 0.3s);
			}
			
			&.external-link {
				padding-right: pxToRem(30);
				&:after {
					content: url(../mysource_files/icon-external-link.svg);
					width: pxToRem(16);
					height: pxToRem(16);
					display: inline-block;
				}
			}

			&:hover,
			&:focus {
				&:after {
		        	right: pxToRem(-5);
				}
			}

		}
	}
}
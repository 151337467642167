/* Module: S */
body {
    &.fixed {
        overflow: hidden;
    }
}

.navigation {
    position: fixed;
    top: pxToRem(100);
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white-two;
    z-index: 99;
    overflow-x: hidden;
    overflow-y: auto;
    display: none;

    &.active {
        display: block;
    }

    &__wrapper {
        @extend %clearfix;        
    }

    li {
        padding: 0;
        margin: 0;
    }

    &__info {
        display: none;
    }

    &__level-1 {
        padding: 0;
        margin: 0;
        list-style-type: none;
        @extend %clearfix;

        &-link,
        &-toggle {
        	border-top: 2px solid $white-three;

        	a,
            button {
        		display: block;
        		font-size: 1.125em; //18
                font-weight: 500;
        		text-decoration: none;
        		color: $evergreen;
        		padding: pxToRem(22) pxToRem(50) pxToRem(22) pxToRem(20);
                position: relative;
                width: 100%;
                text-align: left;
                outline: none;
                border: none;
                background-color: transparent;

        		&:hover,
        		&:focus,
                &.current {
                    background-color: $white-three;
        		}

                &:after {
                    content: url(../mysource_files/chevron-down.svg);
                    width: pxToRem(13);
                    height: pxToRem(13);
                    line-height: 7px;
                    display: block;
                    position: absolute;
                    top: 50%;
                    margin-top: pxToRem(-7);
                    right: pxToRem(20);
                    @include sq-transition(all 0.3s);
                }
        	}

            a {
                &:after {
                    @include sq-transform(rotate(-90deg));
                }
            }

            button {
                &.active {
                    &:after {
                        @include sq-transform(rotate(180deg));
                    }
                }
            }
        }
    }

    &__level-2 {
        background-color: $teal-blue-two;
        background-image: linear-gradient(158deg, $teal-blue-two, $ocean);
        padding: 0;
        margin: 0;
        list-style-type: none;

        &-wrapper {
            display: none;

		    &.active {
		        display: block;
		    }
        }

        > li {
            border-bottom: 2px solid rgba($pine-green, 0.2);

            &:last-child {
                border-bottom: none;
            }

            &.active {
                background-color: $ocean;
            }
        }

        &-link {
            position: relative;

        	a {
        		display: block;
                width: 100%;
                font-size: 1.250em; //20
                font-weight: 500;
                line-height: 1.5;
        		text-decoration: none;
        		color: $white-four;
                @include font-smoothing;
        		padding: pxToRem(16) pxToRem(56) pxToRem(16) pxToRem(20);

        		&:hover,
        		&:focus,
                &.current {
        			background-color: $ocean;
        		}
        	}

            button {
                position: absolute;
                top: 50%;
                right: 0;
                height: pxToRem(56);
                width: pxToRem(56);
                margin-top: pxToRem(-28);
                background-color: transparent;
                border: none;
            
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 50%;
                    background-color: $white;
                    @include sq-transition((opacity .3s, transform .3s));   
                }

                &:before {
                    width: pxToRem(2);
                    height: pxToRem(16);
                    margin-right: pxToRem(-1);
                    margin-top: pxToRem(-8);
                }

                &:after {
                    width: pxToRem(16);
                    height: pxToRem(2);
                    margin-right: pxToRem(-8);
                    margin-top: pxToRem(-1);
                }

                &.active {
                    &:before {
                        @include sq-transform(rotate(90deg));
                    }

                    &:after {
                        @include sq-transform(rotate(90deg));
                        opacity: 0;
                    }
                }
            }
        }
    }

    &__level-3 {
        display: none;
        background-color: $ocean;
        border-top: 2px solid rgba($teal-blue-two, 0.4);
        padding: 0;
        margin: 0;
        list-style-type: none;

        &.active {
        	display: block;
        }

        li {
        	border-bottom: 2px solid rgba($pine-green, 0.2);

            &:last-child {
                border-bottom: none;
            }

        	a {
        		display: block;
        		font-size: 1em; //16
                font-weight: 500;
                line-height: 1.38;
        		text-decoration: none;
                @include font-smoothing;
        		color: $white;
        		padding: pxToRem(14) pxToRem(20) pxToRem(14) pxToRem(54);
                position: relative;

        		&:hover,
        		&:focus {
        			background-color: rgba($pine-green, 0.2);
        		}

                &:after {
                    content: url(../mysource_files/arrow-long-right-teal-light.svg);
                    width: pxToRem(25);
                    height: pxToRem(16);
                    line-height: pxToRem(16);
                    display: block;
                    position: absolute;
                    top: 50%;
                    margin-top: pxToRem(-8);
                    left: pxToRem(17);
                }
        	}

        }
    }

    &__secondary {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            border-top: 2px solid $white-three;
        }

        a {
            display: block;
            font-size: 1em; //16
            font-weight: 500;
            text-decoration: none;
            color: $pine-green;
            padding: pxToRem(18) pxToRem(20);
            width: 100%;
            outline: none;
            background-color: $white-four;

            &:hover,
            &:focus {
                background-color: $white-three;
            }

        }
    }

}
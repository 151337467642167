/* Module: S */
.breadcrumbs {	
	padding: pxToRem(24) pxToRem(20);

	a { 
		color: $battleship-grey-two;
		text-decoration: none;
		@include font-smoothing;

		&:hover,
		&:focus {
			text-decoration: underline;

			&:before {
				text-decoration: none;
			}
		}
	}

	span {
		font-weight: normal;	
	}

	ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
		@extend %clearfix;
	}

	li {
		padding: 0;
		margin: 0;
		float: left;
		display: none;
		font-size: 0.875em; //14
		font-weight: bold;
		color: $battleship-grey-two;

		&:before {
			content: '<';
			font-weight: normal;
			color: $teal-blue;
			margin-right: pxToRem(7);
		}

		&:nth-last-child(2) {
			display: block;
		}
	}
}
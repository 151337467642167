/* Module: S */
.tabs {
	padding: pxToRem(10) 0;

	&__item {
		margin-top: pxToRem(2);

		&:first-child {
			margin-top: 0;
		}
	}

	&__controls {
		display: none;
		@extend %clearfix;
	}

	&__title {
	    position: relative;


		button {
		    width: 100%;
		    padding: pxToRem(20) pxToRem(45) pxToRem(20) pxToRem(20);
		    border: none;
		    border-radius: 0;
		    background-color: $white;
		    -webkit-appearance: none;
		    text-align: left;
		    font-size: 1.063em; //17
		    font-weight: bold;
  			line-height: 1.4; 
		    color: $dark-slate-blue;
		    @include font-smoothing;

		    &:hover,
		    &:focus {
		    	color: $white;
		    	background-color: $ocean;

		    	&:before,
		    	&:after {
	                background-color: $white;
		    	}
		    }
	        
	        &:before,
	        &:after {
	            content: '';
	            position: absolute;
	            top: pxToRem(32);
	            right: pxToRem(26);
	            background-color: $petrol;
	            @include sq-transition((opacity .3s, transform .3s)); 
	            z-index: 1;
	            border-radius: pxToRem(2);  
	        }

	        &:before {
	            width: pxToRem(2);
	            height: pxToRem(12);
	            margin-right: pxToRem(-1);
	            margin-top: pxToRem(-6);
	        }

	        &:after {
	            width: pxToRem(12);
	            height: pxToRem(2);
	            margin-right: pxToRem(-6);
	            margin-top: pxToRem(-1);
	        }
		}

        &.active {
            button {
		    	color: $white;
	    		background-color: $ocean;

	            &:before {
	                @include sq-transform(rotate(90deg));
	                background-color: $white;
	            }

	            &:after {
	                @include sq-transform(rotate(90deg));
	                opacity: 0;
	                background-color: $white;
	            }
            }
        }
	}

	&__text {
		margin-top: pxToRem(2);
		font-size: 1em; //16
		line-height: 1.5;
		padding: pxToRem(32) pxToRem(20) pxToRem(30) pxToRem(20);
		display: none;
		color: $battleship-grey;
		background-color: $white;

		&.active {
			display: block;
		}

		p {
			margin: 0;
			padding: 0 0 pxToRem(10) 0;

			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}
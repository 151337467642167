/* Module: S */
.atp-search {

    input,
    button {
        &:disabled {
            opacity: 0.5;

            &~label {
                opacity: 0.5;
            }
        }
    }

    &__wrapper {
        background-color: $grey;
        padding: pxToRem(16) 0;
        position: relative;
    }

    &__form {
        padding: 0 pxToRem(20);

        &--absolute {
            position: absolute;
            top: pxToRem(38);
            left: 0;
            z-index: 1;
            width: 100%;
            right: 0;
            height: auto;
        }
    }

    &__type {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: pxToRem(20);

        &-text {
            color: $pine-green;
            font-size: pxToRem(13);
            font-weight: 500;
            letter-spacing: pxToRem(-0.13);
        }

        &-buttons {
            font-size: 0;
            position: relative;
            display: flex;
            flex-wrap: wrap;

            button {
                border: pxToRem(1) solid $ocean;
                font-size: pxToRem(11);
                font-weight: 500;
                padding: pxToRem(6) pxToRem(8);
                width: pxToRem(88);
                color: $pine-green;
                text-align: center;

                &.border-left {
                    border-radius: pxToRem(20) 0 0 pxToRem(20);
                }

                &.border-right {
                    border-radius: 0 pxToRem(20) pxToRem(20) 0;
                }

                &:hover,
                &:focus {
                    background-color: $petrol;
                    color: $white;
                    @include font-smoothing;
                }

                &.active {
                    background-color: $ocean;
                    color: $white;
                    @include font-smoothing;
                }
            }
        }
    }

    &__address {
        &-btn-wrapper {
            position: relative;

            button {
                width: pxToRem(64);
                white-space: nowrap;
                overflow: hidden;
                position: relative;
                z-index: 3;

                &:first-child {
                    padding-right: 0;

                    &.active {
                        &:after {
                            content: "";
                            display: inline-block;
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin-top: auto;
                            margin-bottom: auto;
                            height: 80%;
                            width: pxToRem(1);
                            background-color: rgba($white, 0.5);
                        }
                    }
                }

                &:nth-child(2) {
                    border-left: 0;
                    padding-left: 0;
                    padding-right: pxToRem(12);

                    &.active,
                    &:hover {

                        &:before,
                        &:after {
                            background-color: rgba($white, 0.8);
                        }

                    }

                    &:before,
                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        height: pxToRem(1);
                        width: pxToRem(6);
                        background-color: rgba($ocean, 1);
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    &:before {
                        transform: rotate(45deg);
                        right: pxToRem(6);
                    }

                    &:after {
                        transform: rotate(-45deg);
                        right: pxToRem(2);
                    }
                }
            }
        }

        &-options-wrapper {
            display: none;
            position: absolute;
            width: 100%;
            background-color: $white;
            border: pxToRem(2) solid $ocean;
            z-index: 2;
            top: 0;
            border-radius: pxToRem(20);
            padding: pxToRem(30) 0 0;

            &.active {
                display: block;
            }
        }

        &-option {
            display: block;
            font-size: pxToRem(12);
            text-align: center;
            padding: pxToRem(8);
            margin: 0 pxToRem(15);
            border-bottom: pxToRem(1) solid rgba($light-grey, 0.5);

            &:hover,
            &:focus {
                background-color: darken($white, 5%);
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }


    &__field {
        position: relative;

        input[type="text"] {
            border-radius: pxToRem(50);
            border: none;
            width: 100%;
            padding: pxToRem(14) pxToRem(28);
            font-size: pxToRem(14);
            color: $pine-green;
            opacity: 0.75;
            margin-bottom: pxToRem(10);
            background-color: $white;
            border: pxToRem(1) solid $white;

            &::placeholder {
                font-size: pxToRem(14);
                color: $pine-green;
                opacity: 0.75;
            }

            &:focus,
            &.active {
                border: pxToRem(1) solid $ocean;
            }
        }

        &-buttons {
            font-size: 0;
            position: absolute;
            bottom: pxToRem(25);
            right: pxToRem(25);
        }

        &-button,
        &-filter {
            border: none;
            border-radius: 0;
            background-color: transparent;
            padding: 0;
            position: relative;
            width: pxToRem(22);
            height: pxToRem(22);
            margin: 0;
            background-image: none;
            z-index: 1;
            @include clear-default-appearance;

            &::after {
                display: block;
                position: absolute;
            }
        }

        &-button {
            &::after {
                content: url(../mysource_files/search-grey.svg);
                display: block;
                position: absolute;
                bottom: 0;
                right: 0;
                top: 0;
                left: 0;
                height: pxToRem(15);
                width: pxToRem(15);
                margin: auto;
            }
        }

        &-separator {
            display: inline-block;
            width: pxToRem(1);
            height: pxToRem(24);
            margin: pxToRem(0) pxToRem(15);
            background-color: rgba($dark-grey, 0.1);
        }

        &-filter {
            &::after {
                content: url(../mysource_files/filter-grey.svg);
                display: block;
                position: absolute;
                bottom: 0;
                right: 0;
                top: 0;
                left: 0;
                height: pxToRem(15);
                width: pxToRem(15);
                margin: auto;
            }

            &.active {
                &::after {
                    content: url(../mysource_files/filter-teal.svg);
                }
            }
        }

        &::before {
            content: '';
            // display: block;
            display: none;
            position: absolute;
            top: pxToRem(12);
            right: pxToRem(60);
            width: pxToRem(1);
            height: pxToRem(24);
            background-color: rgba($dark-grey, 0.1);
            z-index: 1;
        }
    }

    &__keywords {
        display: none;
        position: absolute;
        top: auto;
        left: 0;
        bottom: auto;
        right: 0;
        background-color: $white;
        width: 100%;
        // height: 100%;
        overflow: auto;
        max-height: pxToRem(277);
        padding: pxToRem(26) pxToRem(36);
        z-index: 3;
        border-radius: pxToRem(5);

        &-title {
            display: block;
            color: $pine-green;
            opacity: 0.5;
            font-size: pxToRem(14);
            padding-bottom: pxToRem(20);
        }


        &.active {
            display: block;
        }

        &-suggestion {
            display: block;
            font-size: pxToRem(14);
            font-weight: 500;
            letter-spacing: pxToRem(-0.14);
            color: $pine-green;
            text-transform: capitalize;

            &:not(:last-child) {
                padding-bottom: pxToRem(20);
            }

            &--active {
                text-decoration: underline;
            }
        }
    }

    &__button {
        display: none;

        &-map {
            border-radius: pxToRem(20) 0 0 pxToRem(20);

            &.active {
                background-color: $ocean;
                color: $white;
                @include font-smoothing;
            }
        }

        &-list {
            border-radius: 0 pxToRem(20) pxToRem(20) 0;

            &.active {
                background-color: $ocean;
                color: $white;
                @include font-smoothing;
            }
        }
    }

    &__location {
        position: relative;

        &-options {
            padding: pxToRem(30) pxToRem(20) pxToRem(10) pxToRem(20);
            font-size: 0;

            button {
                border: pxToRem(1) solid $ocean;
                font-size: pxToRem(13);
                font-weight: 500;
                padding: pxToRem(10) pxToRem(20);
                color: $ocean;
                background-color: $white;

                &:hover,
                &:focus {
                    background-color: $petrol;
                    color: $white;
                    @include font-smoothing;
                }
            }

            &--absolute {
                position: absolute;
                bottom: pxToRem(38);
                left: 0;
                z-index: 1;
                width: pxToRem(200);
                right: 0;
                height: auto;
            }
        }
    }

    &__zoom {
        display: flex;
        flex-direction: column;
        margin: pxToRem(20);
        z-index: 1;

        &-btn {
            width: pxToRem(46);
            height: pxToRem(46);
            margin: pxToRem(4);
            border: none;
            font-size: pxToRem(28);
            background-color: $white;
            color: $ocean;

            &:hover,
            &:focus {
                background-color: $ocean;
                color: $white;
            }
        }
    }

    &__results {
        display: none;

        &.active {
            display: block;
        }

        &-heading {
            padding: pxToRem(10) pxToRem(20);
            font-size: pxToRem(13);
            font-weight: 500;
            color: $dark-grey;
        }

        &-details {
            background-color: rgba($greyish-white, 0.6);
            border-top: pxToRem(0.5) solid rgba($ocean, 0.3);
            border-bottom: pxToRem(0.5) solid rgba($ocean, 0.3);
            padding: pxToRem(8) pxToRem(20) pxToRem(8) pxToRem(40);
            // margin: 0 pxToRem(20) 0 0;

            ul {
                padding: 0 pxToRem(20) 0 0;
                // margin: 0 pxToRem(20) 0 0;
                list-style-type: none;
                text-indent: 0;
                max-height: pxToRem(320);
                overflow-y: scroll;
                overflow-x: hidden;
                margin: 0;

                &::-webkit-scrollbar {
                    width: pxToRem(5);
                    height: pxToRem(12);
                }

                &::-webkit-scrollbar-track {
                    background-color: rgba($black, 0.05);
                    background-clip: content-box;
                    border-radius: pxToRem(4);
                    height: pxToRem(12);
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $ocean;
                    border-radius: pxToRem(4);
                    height: pxToRem(12);
                }

                li {
                    &:not(:last-child) {
                        a {
                            border-bottom: pxToRem(0.5) solid rgba($dark-grey, 0.1);
                        }
                    }
                }

                a {
                    text-indent: 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding: pxToRem(12) 0;
                }
            }
        }

        &-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            span {
                display: block;
            }
        }

        &-icon {
            display: block;
            color: $blue-green;

            svg {
                width: pxToRem(16);
                height: pxToRem(22);
            }

            &:focus {}
        }

        &-summary {
            padding-left: pxToRem(16);
        }

        &-title {
            font-size: pxToRem(13);
            font-weight: 500;
            color: $pine-green;
        }

        &-caption,
        &-distance {
            font-size: pxToRem(10);
            color: $pine-green;
        }

        &-address {
            font-size: pxToRem(10);
            font-weight: bold;
            color: $pine-green;
        }
    }

    &__map {
        height: pxToRem(540);
        width: 100%;
        display: none;

        &.active {
            display: block;

            &~.atp-search__filters {
                top: pxToRem(128);
                z-index: 2;
            }
        }
    }

    &__filters {
        margin: 0 pxToRem(20);
        position: absolute;
        width: calc(100% - 40px);
        height: auto;
        top: 0;
        left: 0;
        overflow-y: auto;
        display: none;

        &.active {
            display: block;
            z-index: 1;
        }

        &-header {
            border-top-right-radius: pxToRem(5);
            border-top-left-radius: pxToRem(5);
            background-color: $ocean;
            padding: pxToRem(12) pxToRem(28);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            @include font-smoothing;
        }

        &-headline {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }

        &-icon {
            svg {
                vertical-align: middle;
                width: pxToRem(17);
                height: pxToRem(14);
            }
        }

        &-heading {
            font-size: pxToRem(16);
            font-weight: 500;
            letter-spacing: pxToRem(-0.16);
            color: $white;
            padding-left: pxToRem(12);
            line-height: normal;
        }

        &-close {
            display: block;
            border: none;
            border-radius: 0;
            background-color: transparent;
            padding: 0;
            position: relative;
            margin: 0;
            background-image: none;
        }

        &-details {
            background-color: $white;
            padding: pxToRem(28) pxToRem(36) pxToRem(24) pxToRem(36);
            border-bottom-right-radius: pxToRem(5);
            border-bottom-left-radius: pxToRem(5);
        }

        &-title {
            font-size: pxToRem(16);
            font-weight: 500;
            letter-spacing: pxToRem(-0.16);
            color: $pine-green;
            margin-bottom: pxToRem(15);
        }

        &-category {
            position: relative;

            ul {
                padding: 0 0 pxToRem(15) 0;
                margin: 0;
                list-style-type: none;
                text-indent: 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
            }

            li {
                width: 50%;
                margin: 0;
                text-indent: 0;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                &:not(:last-child) {
                    padding: 0 0 pxToRem(12) 0;
                }
            }

            input[type="checkbox"],
            input[type="radio"] {
                position: absolute;
                opacity: 0;
                height: 0;
                width: 0;

                &+label {
                    display: block;
                    position: relative;
                    padding-left: pxToRem(26);
                    cursor: pointer;
                    font-size: pxToRem(14);
                    letter-spacing: pxToRem(-0.14);
                    color: $pine-green;

                    &::before,
                    &::after {
                        display: block;
                        content: '';
                        position: absolute;
                    }

                    &::before {
                        transform: translateY(-50%);
                        left: 0;
                        top: pxToRem(11);
                        width: pxToRem(11);
                        height: pxToRem(11);
                        border: pxToRem(1) solid $pine-green;
                        background-color: $white;
                    }
                }

                &:checked {
                    &+label {
                        &::before {
                            border: pxToRem(1) solid $ocean;
                        }

                        &::after {
                            content: url(../mysource_files/tick-teal.svg);
                            left: pxToRem(2);
                            top: pxToRem(0);
                        }
                    }
                }

                &:focus {
                    &+label {
                        &::before {
                            // outline: auto pxToRem(1) orange;
                        }
                    }
                }

            }
        }
    }

    &__infowindow {
        max-width: pxToRem(250);
        font-weight: 500;
        font-size: pxToRem(12);
        word-break: break-word;

        a {
            color: $ocean;
            text-decoration: underline;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        svg {
            width: pxToRem(10);
            height: pxToRem(15);
            margin: 0 pxToRem(4) 0 0;
            flex-shrink: 0;
        }

        &-heading {
            background-color: $ocean;
            color: $white;
            padding: pxToRem(20) pxToRem(25);
            display: flex;
            flex-wrap: no-wrap;
            justify-content: space-between;
            align-items: flex-start;
            font-size: pxToRem(14);
        }

        &-close {
            border: none;
            background: none;
            padding: 0;
            margin: 0;
            height: pxToRem(10);
            width: pxToRem(10);
            position: relative;
            flex-shrink: 0;
            margin-left: pxToRem(5);

            &:before,
            &:after {
                content: '';
                background-color: $white;
                display: block;
                width: pxToRem(10);
                height: pxToRem(2);
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }

        &-content {
            background-color: $white;
            padding: pxToRem(25);
        }

        &-row {
            margin-bottom: pxToRem(8);
            display: flex;
            flex-wrap: no-wrap;
        }

        &-key {
            display: block;
            opacity: 0.5;
            min-width: pxToRem(65);
            flex-shrink: 0;
            padding: 0 pxToRem(8) 0 0;
        }

        &-value {
            -ms-word-break: break-all;
            display: block;
        }

        &-address {
            width: 100%;

            a {
                display: flex;
                font-size: pxToRem(10);
                color: $pine-green;
                text-decoration: none;
                width: 100%;

                span {
                    flex-basis: 100%;
                }

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
    }

    &__cluster {
        span {
            color: $white;
        }
    }

    // Google InfoWindow style override
    .gm-style-iw {
        border-radius: pxToRem(5);
        padding: 0;
    }

    .gm-style-iw-d {
        overflow: auto !important;
    }

    .gm-ui-hover-effect {
        display: none !important;
    }

    .twitter-typeahead {
        display: block !important;
    }

    .tt-hint {
        opacity: 0.75 !important;
    }
}
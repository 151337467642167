/* Module: S */
.footer {
	background-color: $ocean;
	@include font-smoothing;
	padding: pxToRem(20) 0;

	&__wrapper {
		@extend %clearfix;
	}

	&__logo {
		padding: 0 pxToRem(28);

		img {
			width: auto;
			height: pxToRem(54);
		}
	}

	ul {
		list-style-type: none;
		padding: pxToRem(16) pxToRem(32);
		margin: 0;
	}

	li {
		padding: pxToRem(14) 0;
		margin: 0;
	}

	a {
		color: $white;
		text-decoration: none;
		font-size: 0.875em; //14
		font-weight: 500;

		&:hover,
		&:focus {
			text-decoration: underline;
		}

		&.external-link {
			position: relative;

			&:after {
				content: url(../mysource_files/icon-external-link-white.svg);
				width: 12px;
				right: pxToRem(-6);
				top: 0;
				height: 12px;
				position: relative;
				display: inline-block;
			}
		}
	}
}
/* Module: S */
.filters {
    button {
        min-width: pxToRem(162);
        text-align: center;
        padding: pxToRem(12) pxToRem(44) pxToRem(12) pxToRem(20);

        &[type="reset"] {
            min-width: pxToRem(130);
        }

    }

    ul {
        left: pxToRem(32);
        right: pxToRem(32);
        top: pxToRem(43);
    }

	&__info {
        float: left;
        clear: left;
	}

	&__list {
        margin: 0 pxToRem(-10) pxToRem(-10) pxToRem(-10);
        float: left;
	}

	&__item {
        padding: 0 pxToRem(10) pxToRem(10) pxToRem(10);
        float: left;
        width: auto;

        &-large {
            button {
                min-width: pxToRem(186);
            }
        }
	}
}
/* Module: S */
.filters {
	padding: pxToRem(12) pxToRem(20) 0 pxToRem(20);

	&__wrapper {
		@extend %clearfix;
	}

	&__info {
		color: $battleship-grey-two;
    	font-size: 1em; //16
		padding: pxToRem(30) 0;
	}

	&__list {
		margin: pxToRem(-5) 0;
	}

	&__item {
		position: relative;
		max-width: pxToRem(320);
		padding: pxToRem(5) 0;
	}

    button {
    	background-color: $white;
    	border: none;
    	border-radius: pxToRem(36);
    	display: block;
    	color: $twilight-blue;
    	font-size: 1em; //16
    	font-weight: 500;
    	line-height: 1.24;
    	padding: pxToRem(12) pxToRem(22);
    	width: 100%;
    	text-align: left;
    	position: relative;
    	outline: none;
    	@include font-smoothing;

    	&:hover,
    	&:focus,
    	&.active {
    		color: $teal-blue;
    	}

    	&.active {
    		z-index: 2;

	    	&:before {
	    		content: '';
	    		position: absolute;
	    		bottom: 0;
		    	left: pxToRem(22);
		    	right: pxToRem(22);
	    		background-color: $teal-blue-two;
	    		height: pxToRem(2);
	    	}

	    	&:after {
	    		@include sq-transform(rotate(180deg));
	    	}
    	}

        &:after {
            content: url(../mysource_files/chevron-down.svg);
            width: pxToRem(13);
            height: pxToRem(13);
            line-height: 7px;
            display: block;
            position: absolute;
            top: 50%;
            margin-top: pxToRem(-6);
            right: pxToRem(21);
    		@include sq-transition(all 0.3s);
        }

        &[type="reset"] {
            background-color: $ocean;
            color: $white;
            padding-right: pxToRem(45);

            &:before {
                content: url(../mysource_files/reset.svg);
                width: pxToRem(21);
                height: pxToRem(18);
                line-height: 18px;
                display: block;
                position: absolute;
                top: 50%;
                margin-top: pxToRem(-9);
                right: pxToRem(21);
            }

            &:after {
                display: none;
            }

            &:hover,
            &:focus,
            &.active {
                color: $white;
                background-color: $petrol;
            }
        }
    }

    ul {
    	list-style-type: none;
    	padding: 0;
    	margin: 0;
    	box-shadow: 0 pxToRem(2) pxToRem(10) 0 rgba(199, 199, 199, 0.38);
    	position: absolute;
    	top: pxToRem(48);
    	left: pxToRem(22);
    	right: pxToRem(22);
    	z-index: 1;
    	display: none;

    	&.active {
    		display: block;
    	}
    }

    li {
    	padding: 0;
    	margin: 0;
    	border-top: 2px solid $white-four;

    	&:first-child {
    		border: none;
    	}
    }

    a {
    	color: $twilight-blue;
    	font-size: 0.875em; //14
    	font-weight: 500;
    	line-height: 1.45;
    	@include font-smoothing;
    	text-decoration: none;
    	display: block;
    	background-color: $white;
    	padding: pxToRem(5) pxToRem(9);

    	&:hover,
    	&:focus {
    		color: $teal-blue;
    	}
    }
}
/* Module: S */
.landing-tiles {
	padding: 0 pxToRem(20) pxToRem(50) pxToRem(20);

	& + .landing-tiles {
		margin-top: pxToRem(-50);
	}

	&__intro {
		font-size: 1em; //16
		line-height: 1.5;
		text-align: center;
		padding-top: pxToRem(30);
		position: relative;
		max-width: pxToRem(620);
		margin: 0 auto;
		color: $spruce;

		&:last-child {
			margin-bottom: pxToRem(-40);
		}

		&:after {
			content: '';
			position: absolute;
			width: pxToRem(40);
			height: pxToRem(2);
			top: pxToRem(3);
			left: 50%;
			margin-left: pxToRem(-20);
			background-color: $teal-blue;
		}

		p {
			margin: 0;
			padding-bottom: pxToRem(10);

			&:last-child {
				padding-bottom: 0;
			}
		}
	}

	&__wrapper {
		padding-top: pxToRem(30);

		&:first-child {
			padding-top: 0;
		}
	}

	&__item {
		padding: pxToRem(10) 0;
	}

	a {
		background-color: $white;
		display: block;
		padding: pxToRem(30) pxToRem(20) pxToRem(56) pxToRem(20);
		min-height: pxToRem(212);
		text-decoration: none;
		position: relative;

	    &:before,
	    &:after {
	        content: url(../mysource_files/arrow-long-right-teal-light.svg);
	        width: pxToRem(25);
	        height: pxToRem(16);
	        line-height: pxToRem(16);
	        display: block;
	        position: absolute;
	        bottom: pxToRem(22);
	        left: pxToRem(20);
	        @include sq-transition(left 0.3s);
	    }

	    &:before {
	    	opacity: 0;
	        content: url(../mysource_files/arrow-long-right-teal-dark.svg);
	    }

		&:hover,
		&:focus {
			box-shadow: 0 pxToRem(2) pxToRem(10) pxToRem(4) rgba(199, 199, 199, 0.5);

			&:before {
	        	left: pxToRem(25);
	        	opacity: 1;
			}

			&:after {
				opacity: 0;
	        	left: pxToRem(25);
			}

			.landing-tiles {
				&__title {
					color: $ocean;
				}
			}
		}
	}

	&__title {
		font-size: 1.125em; //18
		font-weight: 500;
		line-height: 1.67;
		color: $spruce;
		@include font-smoothing;
	}

	&__text {
		font-size: 0.875em; //14
		line-height: 1.71;
		color: $battleship-grey;
		padding-top: pxToRem(13);

		p {
			font-size: pxToRem(14); //14
			line-height: 1.71;
			padding: 0;
		}
	}
}
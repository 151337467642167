/* Module: S */
.block-tiles {
	margin: pxToRem(-20) pxToRem(-10);
	padding: 0;

	&__wrapper {
		@include sq-flex-box();
	}

	&__item {
		padding: pxToRem(50) pxToRem(40) pxToRem(64) pxToRem(40);
		margin: pxToRem(20) pxToRem(10);
		width: 33.33%;

		&--featured {
			.news-tiles {
				&__content {
					padding: pxToRem(40) pxToRem(30) pxToRem(53) pxToRem(30);
				}
			}

			a {
			    &:after {
			        bottom: pxToRem(42);
			        left: pxToRem(30);
			    }

				&:hover,
				&:focus {
					&:after {
			        	left: pxToRem(35);
					}
				}
			}
		}
	}

	&--featured {
		.news-tiles {
			&__item {
				&:first-child {
					width: 50%;
				}
			}
		}
	}
}
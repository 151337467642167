/* Module: S */
.landing-tiles {
	margin: 0 pxToRem(-10);
	padding: 0 0 pxToRem(32) 0;

	&__item {
		width: 33.333%;
	}

	&__text {
		p {
			padding: 0;
		}
	}
}
/* Module: S */
.quick-links {
	&__item {
		&:first-child {
			.quick-links {
				&__title {
					background-color: $sea;
				}
			}

			a {
				&:before {
					content: url(../mysource_files/arrow-long-right-teal-light.svg);
				}
			}
		}

		&:last-child {
			.quick-links {
				&__title {
					background-color: $petrol;
				}
			}

			a {
				&:before {
					content: url(../mysource_files/arrow-long-right-teal-dark.svg);
				}
			}
		}
	}

	&__title {
		position: relative;
		background-color: $teal-blue;

		> span {
			display: block;
			color: $white-four;
			@include font-smoothing;
			font-size: 1.625em; //26
			font-weight: 500;
			line-height: 1.31;
			padding: pxToRem(11) pxToRem(20);
			max-width: pxToRem(257);
		}

		button {
			@include clear-default-appearance;
			background-color: transparent;
			border: none;
			display: block;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			&:after {
				content: url(../mysource_files/arrow-long-right-white.svg);
				width: pxToRem(28);
				height: pxToRem(17);
				line-height: pxToRem(17);
				display: block;
				position: absolute;
				top: 50%;
				margin-top: pxToRem(-8);
				right: pxToRem(12);
				@include sq-transform(rotate(90deg));
			}

			&.active {
				&:after {
					@include sq-transform(rotate(-90deg));
				}
			}
		}
	}

	&__content {
		display: none;

		&.active {
			display: block;
		}
	}

	ul {
		list-style-type: none;
		padding: pxToRem(10) pxToRem(20);
		margin: 0;
	}

	li {
		padding: pxToRem(10) 0;
		margin: 0;
	}

	a {
		color: $pine-green;
		text-decoration: none;
		font-size: 1em; //16
		font-weight: 500;
		line-height: 1.63;
		display: inline-block;
		padding: 0 0 0 pxToRem(34);
		position: relative;

		&:hover,
		&:focus {
			&:before {
				left: pxToRem(5);
				opacity: 0.5;
			}
		}

		&:before {
			content: url(../mysource_files/arrow-long-right-teal.svg);
	        width: pxToRem(25);
	        height: pxToRem(16);
			line-height: pxToRem(16);
			display: block;
			position: absolute;
			top: 50%;
			margin-top: pxToRem(-8);
			left: 0;
			@include sq-transition(left 0.3s, opacity 0.3s);
		}
	}
}
/* Module: S */
.header {
	position: relative;
	background-color: $white-two;
	height: pxToRem(100);

	&.static {
		.header__wrapper {
			position: absolute;
		}
	}

	&__wrapper {
		@extend %clearfix;
		background-color: $white-two;
		position: fixed;
		//position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 10; 
		box-shadow: 0 pxToRem(4) pxToRem(10) 0 rgba($black, 0.1);
	}

    &__accessibility {
        display: none;
    }

    &__title {
		@include font-smoothing;
		background-color: $ocean;
		padding: pxToRem(7) pxToRem(20);
		float: left;
		width: 100%;

		a {
			font-size: 16px; //16
			font-weight: 500;
			color: $white-two;
			text-decoration: none;			
		}
    }

	&__toggle {
		float: right;
		padding: pxToRem(21) pxToRem(20) pxToRem(23) pxToRem(20);

		button {
			padding: 0;
			margin: 0;
			background-color: transparent;
			border: none;
			-webkit-appearance: none;
			display: block;
		}

		.icon {
			display: block;
			width: pxToRem(24);
			height: pxToRem(19);
			position: relative;
			@include sq-transform(rotate(0deg));
			@include sq-transition(.5s ease-in-out);
			cursor: pointer;
			overflow: hidden;

			span {
				display: block;
				position: absolute;
				height: pxToRem(2.4);
				border-radius: pxToRem(2);
				width: 100%;
				background-color: $teal-blue;
				opacity: 1;
				left: 0;
				@include sq-transform(rotate(0deg));
				@include sq-transition(.25s ease-in-out);
			}

			span:nth-child(1) {
				top: 0;
			}

			span:nth-child(2),
			span:nth-child(3) {
				top: pxToRem(8);
			}

			span:nth-child(4) {
				top: pxToRem(16);
			}
		}

		&.active {
			.icon {
				span:nth-child(1) {
					top: pxToRem(25);
					width: 0%;
					left: 50%;
				}

				span:nth-child(2) {
					@include sq-transform(rotate(45deg));
				}

				span:nth-child(3) {
					@include sq-transform(rotate(-45deg));
				}

				span:nth-child(4) {
					top: pxToRem(-25);
					width: 0%;
					left: 50%;
				}					
			}
		}
	}

	&__logo {
		float: left;
		padding: pxToRem(9) pxToRem(20) pxToRem(11) pxToRem(20);

		img {
			width: auto;
			max-height: pxToRem(44);
		}
	}


	&__search {
		position: absolute;
		top: pxToRem(36);
		left: 0;
		right: 0;
		background-color: $white;
		padding: pxToRem(35) pxToRem(20);
		@include placeholder-color($evergreen);
		display: none;
		z-index: 100;
		@include font-smoothing;
		box-shadow: 0 pxToRem(4) pxToRem(10) 0 rgba($black, 0.1);

		&.active {
			display: block;
		}

		form {
			position: relative;

			&:after {
                content: url(../mysource_files/search-teal-dark.svg);
				position: absolute;
				bottom: pxToRem(11);
				right: 0;
                width: pxToRem(19);
                height: pxToRem(19);
                line-height: pxToRem(19);
			}
		}

		label {
			display: block;
			font-size: 1em; //16
			line-height: 1.2;
			color: $battleship-grey;
		}

		input[type="text"] {
            font-size: 1.125em; //18
            font-weight: 500;
			color: $pine-green;
			border: none;
			border-bottom: 1px solid $white-four;
			background-color: transparent;
			padding: pxToRem(8) pxToRem(30) pxToRem(9) 0;
			width: 100%;
		}

		input[type="submit"] {
			border: none;
			border-radius: 0;
			background-color: transparent;
			text-indent: -99999px;
			padding: 0;
			@include clear-default-appearance;
			position: absolute;
			bottom: 0;
			right: 0;
			padding: 0;
			width: pxToRem(30);
			height: pxToRem(43);
			display: block;
			margin: 0;
			background-image: none;
			z-index: 1;

			&:hover,
			&:focus,
			&:active {
				background-color: transparent;
			}
		}

		&-toggle {
			position: absolute;
			top: 0;
			right: 0;

			button {
				@include clear-default-appearance;
				border: none;
				border-radius: 0;
				background-color: transparent;
				padding: 0;
				width: pxToRem(58);
				height: pxToRem(36);
				position: relative;
				display: block;
				border-top-left-radius: pxToRem(100);
				border-bottom-left-radius: pxToRem(100);
				outline: none;

				&:hover,
				&:focus {
					background-color: $deep-teal;
				}

                &:after {
                    content: url(../mysource_files/search-teal-light.svg);
                    width: pxToRem(16);
                    height: pxToRem(16);
                    line-height: pxToRem(16);
                    display: block;
                    position: absolute;
                    top: 50%;
                    margin-top: pxToRem(-8);
                    right: pxToRem(20);
                }

                > span {
                	text-indent: -9999px;
                	display: block;
					position: absolute;
					top: 0;
					left: pxToRem(27);
					font-size: 14px;
					line-height: 40px;
					font-weight: 500;
					color: $white-two;
					@include font-smoothing;
                }

                &.active {
                	&:before,
	                &:after {
	                    content: '';
	                    width: pxToRem(16);
	                    height: pxToRem(2);
	                    background-color: $white;
	                    border-radius: pxToRem(2);
	                    position: absolute;
	                    top: 50%;
	                    margin-top: pxToRem(-1);
	                    right: pxToRem(20);
	                }

					&:before {
						@include sq-transform(rotate(45deg));
					}

					&:after {
						@include sq-transform(rotate(-45deg));
					}
                }
			}
		}
	}
}
/* Module: S */
.pagination {
	padding: pxToRem(30) pxToRem(20) pxToRem(40) pxToRem(20);

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		width: 100%;
        @include sq-flex-box;
        @include sq-flex-direction(row);
        @include sq-justify-content(center);

		li {
			padding: 0;
			margin: 0;
			display: block;
			vertical-align: top;

			a {
				text-decoration: none;
				color: $pine-green;
				font-size: 1em; //16
				font-weight: bold;
				line-height: pxToRem(43);
				height: pxToRem(43);
				width: pxToRem(43);
				display: block;
				vertical-align: top;
				position: relative;
				border-radius: 100%;
				box-shadow: 0 pxToRem(2) pxToRem(10) rgba(199, 199, 199, 0.38);
				background-color: $white;
				text-align: center;

				&[href] {
					color: $battleship-grey-two;
					box-shadow: none;
					background-color: transparent;

					&:hover,
					&:focus {
						color: $pine-green;
					}
				}

			}

			&.prev,
			&.next {
				a {
					box-shadow: none;
					background-color: transparent;
					opacity: 0.4;

				    &:after {
				        content: url(../mysource_files/arrow-long-right-teal-light.svg);
				        width: pxToRem(25);
				        height: pxToRem(16);
				        line-height: pxToRem(16);
				        display: block;
				        position: absolute;
				        top: 50%;
				        left: 50%;
				        margin: pxToRem(-8) 0 0 pxToRem(-12);
				        @include sq-transition(margin 0.3s);
				    }

					&[href] {
						opacity: 1;

						&:hover,
						&:focus {
						    &:after {
						        margin-left: pxToRem(-7);
						    }
						}
					}
				}
			}

			&.prev {
				& + li {
					a {
						margin-left: pxToRem(16);

						&[href] {
							margin-left: 0;
						}
					}
				}

				a {
					&:after {
						@include sq-transform(rotate(180deg));
				        margin-top: pxToRem(-10);
					}

					&[href] {
						&:hover,
						&:focus {
						    &:after {
						        margin-left: pxToRem(-17);
						    }
						}
					}
				}
			}
		}
	}
}
/* Module: S */
.navigation {
    &__level-2 {
        &-wrapper {
            height: pxToRem(536);
        }
    }

    &__level-3 {
        li {
            min-width: pxToRem(460);

        	a {
                font-size: 1em; //16
                line-height: 1.2;
                padding: pxToRem(10) pxToRem(20) pxToRem(10) pxToRem(54);
        	}
        }
    }
}
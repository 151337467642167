/* Module: S */
.search {
    position: relative;

    &__partial-list {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: $white;
        z-index: 9;
        box-shadow: 0 pxToRem(6) pxToRem(10) 0 rgba($black, 0.21);
        margin-top: pxToRem(-1);

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            border-top: 1px solid $white-four;
        }

        li {
            list-style-type: none;
            padding: 0;
            margin: 0;
            border-bottom: 1px solid $white-four;
        }

        a {
            font-size: 1em; //16
            line-height: 1.2;
            text-decoration: none;
            padding: pxToRem(10) pxToRem(20);
            display: block;

            &:focus,
            &:hover {
                background-color: $white-four;
            }

            span {
                font-weight: bold;
            }
        }
    }

    &__wrapper {
        padding: 0 pxToRem(20) pxToRem(22) pxToRem(20);

        form {
            padding: 0;
            @include placeholder-color($white);
            position: relative;
            width: 100%;
            margin: 0 auto;

            &:after {
                content: url(../mysource_files/search.svg);
                width: pxToRem(21);
                height: pxToRem(22);
                display: block;
                position: absolute;
                top: pxToRem(19);
                right: pxToRem(19);
            }
        }

        input[type="text"] {
            @include clear-default-appearance;
            background-color: $white;
            color: $spruce;
            width: 100%;
            height: pxToRem(60);
            font-size: 1.125em; //18
            font-weight: 500;
            line-height: pxToRem(60);
            border-radius: pxToRem(36);
            border: none;
            margin: 0;
            padding: 0 pxToRem(80) 0 pxToRem(20);
        }

        input[type="submit"] {
            @includeclear-default-appearance;
            background-color: $teal-blue;
            width: pxToRem(60);
            height: pxToRem(60);
            border-top-right-radius: pxToRem(36);
            border-bottom-right-radius: pxToRem(36);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border: none;
            margin: 0;
            padding: 0;
            position: absolute;
            top: 0;
            right: 0;
            text-indent: -99999px;
            display: block;
            background-image: none;

            &:hover,
            &:focus,
            &:active {
                background-color: $petrol;
            }
        }

        .search {
            &__partial-list {
                left: pxToRem(36);
                right: pxToRem(60);
                margin-top: 0;
            }
        }

    }
}
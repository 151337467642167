/* Module: S */
.header {
	&__wrapper {
		position: absolute;
	}

    &__title {
		background-color: $ocean;
    }

	&__toggle {
		display: none;
	}

	&__search {
		display: none;

		&-toggle {
			display: none;
		}
	}
}
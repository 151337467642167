/* Module: S */
.noticeboard {
	padding: pxToRem(128) pxToRem(20) pxToRem(153) pxToRem(20);
	position: relative;
	background-color: $white;
	background-position: bottom right;
	background-repeat: no-repeat;
	background-size: pxToRem(0);

	&:after {
		content: url(../mysource_files/noticeboard.svg);
		width: pxToRem(44);
		height: pxToRem(59);
		display: block;
		position: absolute;
		top: pxToRem(38);
		left: pxToRem(20);
	}

	&__title {
		font-size: 1.500em; //24
		font-weight: 500;
		line-height: 1.5;
	}

	&__text {
		font-size: 1.125em; //18
		line-height: 1.56;
		color: $battleship-grey;
		padding-top: pxToRem(17);
		max-width: pxToRem(565);
	}

	&__link {
		padding-top: pxToRem(33);

		a.external-link {
			&:after {
				top: 48%;
			}
		}

	}
}
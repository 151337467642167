/* Module: S */
.noticeboard {
	padding: pxToRem(47) pxToRem(180) pxToRem(38) pxToRem(100);
	background-image: url(../mysource_files/bg-noticeboard.jpg);
	background-size: contain;
	background-position: bottom right;

	&:after {
		top: pxToRem(54);
		left: pxToRem(28);
	}

	&__title {
		font-size: 1.750em; //28
		line-height: 1.29;
	}

	&__text {
		padding-top: pxToRem(15);
		max-width: pxToRem(410);
	}

	&__link {
		padding-top: pxToRem(26);
	}
}
/* Module: S */
.accordion {
	padding: pxToRem(10) 0;

	& + .accordion {
		margin-top: pxToRem(-10);
	}

	&__item {
		padding-top: pxToRem(10);

		&:first-child {
			padding-top: 0;
		}
	}

	&__title {
	    position: relative;

	    &:after {
	    	content: '';
	    	position: absolute;
	    	width: pxToRem(30);
	    	height: pxToRem(30);
	    	background-color: $ocean;
	    	border-radius: 100%;
	    	top: 50%;
	    	right: pxToRem(20);
	    	margin-top: pxToRem(-15);
	    	cursor: pointer;
	    }

	    &:hover {
	    	button {
		    	color: $white;
		    	background-color: $teal-blue;
		    	background-image: linear-gradient(102deg, $nice-blue, $teal-blue);
	    	}
	    }

		button {
		    width: 100%;
		    padding: pxToRem(20) pxToRem(64) pxToRem(20) pxToRem(20);
		    border: none;
		    border-radius: 0;
		    background-color: $white;
		    -webkit-appearance: none;
		    text-align: left;
		    font-size: 1.063em; //17
		    font-weight: bold;
  			line-height: 1.4; 
		    color: $dark-slate-blue;
		    @include font-smoothing;

		    &:hover,
		    &:focus {
		    	color: $white;
		    	background-color: $teal-blue;
		    	background-image: linear-gradient(102deg, $nice-blue, $teal-blue);
		    }
	        
	        &:before,
	        &:after {
	            content: '';
	            position: absolute;
	            top: 50%;
	            right: pxToRem(35);
	            background-color: $white;
	            @include sq-transition((opacity .3s, transform .3s, background-color .3s)); 
	            z-index: 1;
	            border-radius: pxToRem(2);  
	        }

	        &:before {
	            width: 2px;
	            height: 12px;
	            margin-right: -1px;
	            margin-top: -6px;
	        }

	        &:after {
	            width: 12px;
	            height: 2px;
	            margin-right: -6px;
	            margin-top: -1px;
	        }
		}

        &.active {
	    	&:after {
	    		background-color: $petrol;
	    	}
	    	
            button {
		    	color: $white;
		    	background-color: $teal-blue;
		    	background-image: linear-gradient(102deg, $nice-blue, $teal-blue);

	            &:before {
	                @include sq-transform(rotate(90deg));
	            }

	            &:after {
	                @include sq-transform(rotate(90deg));
	                opacity: 0;
	            }
            }
        }
	}

	&__text {
		font-size: 1em; //16
		line-height: 1.5;
		padding: pxToRem(17) pxToRem(20);
		display: none;
		color: $battleship-grey;
		background-color: $white;

		&.active {
			display: block;
		}

		p {
			margin: 0;
			padding: 0 0 pxToRem(10) 0;

			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}
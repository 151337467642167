/* Module: S */
.accordion {
	&__title {
	    position: relative;

	    &:after {
	    	display: none;
	    }

		button {
	    	color: $white;
	    	background-color: $teal-blue;
	    	
			&:before,
			&:after {
				display: none;
			}
		}
	}

	&__text {
		display: block;
	}
}
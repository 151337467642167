/* Module: S */
.atp-search {
    &__wrapper {
        padding: 0;
        background: none;
    }

    &__form {
        background-color: $grey;
        padding: pxToRem(30) pxToRem(60);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: pxToRem(20);

        &--absolute {
            position: relative;
            top: pxToRem(0);
        }
    }

    &__type {
        margin: 0;

        &-text {
            padding-right: pxToRem(12);
        }

        &-buttons {
            button {
                font-size: pxToRem(13);
                width: pxToRem(115);
                padding: pxToRem(14) 0;

                &:first-child {
                    border-radius: pxToRem(30) 0 0 pxToRem(30);
                }

                &:nth-child(2) {
                    border-radius: 0 pxToRem(30) pxToRem(30) 0;
                }
            }
        }
    }

    &__address {
        &-btn-wrapper {
            button {
                width: pxToRem(80);

                &:first-child {}

                &:nth-child(2) {
                    border-radius: 0;

                    &:before,
                    &:after {
                        width: pxToRem(8);
                        height: pxToRem(2);
                    }

                    &:before {
                        right: pxToRem(7);
                    }
                }
            }
        }

        &-options-wrapper {
            padding-top: pxToRem(50);
            border-radius: pxToRem(30) pxToRem(30) pxToRem(20) pxToRem(20);
        }

        &-option {
            font-size: pxToRem(14);
            padding: pxToRem(12) pxToRem(8);
        }
    }

    &__field {
        input[type="text"] {
            width: pxToRem(512);
            margin: 0;
            font-size: pxToRem(16);

            &::placeholder {
                font-size: pxToRem(16);
            }
        }

        &-separator {
            display: none;
        }

        &-filter {
            display: none;
        }

        &-button {
            margin: 0;
            position: absolute;
            top: pxToRem(-10);
            right: pxToRem(0);
            bottom: pxToRem(0);
            left: pxToRem(-20);

            &::after {
                content: url(../mysource_files/search-teal.svg);
                top: pxToRem(0);
                right: pxToRem(0);
                bottom: 0;
                left: 0;
                height: auto;
                width: auto;
            }
        }

        &::before {
            display: none;
        }
    }

    &__keywords {
        padding: pxToRem(26) pxToRem(40) pxToRem(40) pxToRem(40);
        top: pxToRem(66);
        margin: pxToRem(10) 0 0 0;
    }

    &__button {
        display: block;
        border-radius: pxToRem(50);
        background-color: $ocean;
        padding: pxToRem(14) pxToRem(54);
        border: none;
        font-size: pxToRem(14);
        font-weight: 500;
        color: $white;
        @include font-smoothing;
        align-items: flex-end;

        &:hover,
        &:focus {
            background-color: $petrol;
        }
    }

    &__location {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: end;
        height: pxToRem(636);

        &-options {
            display: none;
        }
    }

    &__results {
        display: block;
        width: 25%;
        height: 100%;

        &-heading {
            background-color: $ocean;
            padding: pxToRem(26) pxToRem(28);
            color: $white;
            @include font-smoothing;
            border-top-left-radius: pxToRem(5);
        }

        &-details {
            background-color: $white-two;
            border: none;
            padding: pxToRem(28) pxToRem(14) pxToRem(28) pxToRem(28);
            border-bottom-left-radius: pxToRem(5);

            ul {
                padding: 0 pxToRem(28) 0 0;
                max-height: pxToRem(510);
            }
        }

        &-title,
        &-caption {
            padding-bottom: pxToRem(4);
        }
    }

    &__map {
        display: block;
        width: 55%;
        height: 100%;
        border-top-right-radius: pxToRem(5);
        border-bottom-right-radius: pxToRem(5);
    }

    &__filters {
        display: block;
        position: static;
        width: 20%;
        margin: 0;
        margin-left: pxToRem(20);
        height: 100%;
        background-color: rgba($grey-light, 0.3);
        border-bottom-right-radius: pxToRem(5);
        border-bottom-left-radius: pxToRem(5);

        &-header {
            padding: pxToRem(22) pxToRem(28);
        }

        &-heading {
            font-size: pxToRem(18);
            letter-spacing: pxToRem(-0.18);
            padding-left: pxToRem(14);
        }

        &-icon {
            svg {
                width: pxToRem(19);
                height: pxToRem(16);
            }
        }

        &-close {
            display: none;
        }

        &-details {
            background: none;
        }

        &-category {
            ul {
                flex-direction: row;
                flex-wrap: wrap;
            }

            li {
                width: auto;
            }

            input[type="checkbox"],
            input[type="radio"] {
                &+label {
                    padding-left: pxToRem(16);
                    padding-right: pxToRem(8);
                }
            }
        }
    }
}
/* Module: S */
.news-tiles {
	margin: pxToRem(-10) 0;
	clear: left;

	img {
		width: 100%;
	}

	a {
		@include sq-flex-box();
		@include sq-flex-wrap(wrap);
		align-content: flex-start;
		background-color: $white;
		min-height: pxToRem(348);
		padding: pxToRem(34) 0;
		position: relative;
		text-decoration: none;

	    &:after {
	        content: url(../mysource_files/arrow-long-right-teal-light.svg);
	        width: pxToRem(25);
	        height: pxToRem(16);
	        line-height: pxToRem(16);
	        display: block;
	        position: absolute;
	        bottom: pxToRem(30);
	        margin-top: pxToRem(-8);
	        left: pxToRem(20);
	        @include sq-transition(left 0.3s);
	    }

		&:hover,
		&:focus {
			&:after {
	        	left: pxToRem(25);
			}
		}
	}

	&__intro {
		padding: pxToRem(9) pxToRem(20) 0 pxToRem(20);
		margin-bottom: pxToRem(-7);

		.news-tiles {
			&__tag {
				position: relative;
				display: inline-block;
			}

			&__date {
				display: inline-block;
				margin-left: pxToRem(20);
				color: $battleship-grey-two;

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}

	&__header {
	    font-size: 1.250em; //20
	    font-weight: 500;
	    line-height: 1.5;
	    color: $twilight-blue;
	    padding: 0 pxToRem(20) pxToRem(15) pxToRem(20);
	}

	&__tag {
		font-size: 0.875em; //14
		font-weight: 500;
		line-height: 1.86;
		padding: pxToRem(4) pxToRem(10) pxToRem(3) pxToRem(10);
		color: $white-five;
		@include font-smoothing;
		text-transform: uppercase;
		background-color: $ocean;
		position: absolute;
		top: 0;
		left: 0;
	}

	&__date {
		font-size: 1em; //16
		line-height: 1.63;
		color: $battleship-grey;
	}

	&__title {
		font-size: 1em; //16
		line-height: 1.5;
		font-weight: 500;
		padding-top: pxToRem(15);
		color: $spruce;
	}

	&__content {
		padding: pxToRem(28) pxToRem(20);
		width: 100%;
	}

	&__image {
		width: 100%;
		margin-top: pxToRem(-34);
	}

	&--featured {
		.news-tiles {
			&__content {
				padding: pxToRem(28) 0;
			}
		}

		a {
			&:after {
				left: 0;
			}

			&:hover,
			&:focus {
				&:after {
		        	left: pxToRem(5);
				}
			}
		}
	}

	&__item {
		padding: pxToRem(8) pxToRem(20);

		&:after {
			content: '';
			display: block;
			width: 100%;
			height: pxToRem(2);
			background-color: $teal-blue;
		}

		&--centered {
			a {
				align-items: center;
				align-content: center;
			}
		}

		&--featured {
			padding-bottom: pxToRem(18);

			a {
				color: $white-four;
				@include font-smoothing;
				background-size: cover;
				background-position: center;
				align-content: flex-end;
				align-items: flex-end;
				padding: 0;

			    &:after {
			        content: url(../mysource_files/arrow-long-right-white.svg);
			        width: pxToRem(30);
			        height: pxToRem(18);
			        line-height: pxToRem(18);
			        bottom: pxToRem(32);
			        margin-top: pxToRem(-9);
			        left: pxToRem(20);
			    }

				&:hover,
				&:focus {
					&:after {
			        	left: pxToRem(25);
					}
				}
			}

			.news-tiles {
				&__content {
					background-color: rgba($ocean, 0.8);
					padding: pxToRem(20) pxToRem(20) pxToRem(38) pxToRem(20);
					margin-bottom: pxToRem(18);
					position: relative;

					&:after {
						content: '';
						display: block;
						background-color: $white;
						height: pxToRem(18);
						position: absolute;
						bottom: pxToRem(-18);
						left: 0;
						right: 0;
					}
				}

				&__title {
					font-size: 1.250em; //20
					line-height: 1.5;
					color: $white;
				}
			}
		}
	}

	&--separated {
		padding: pxToRem(10) 0 pxToRem(50) 0;
	}
}
/* Module: S */
.breadcrumbs {	
	padding: pxToRem(30) 0 pxToRem(20) 0;

	li {
		display: block;
		padding-left: pxToRem(28);
		position: relative;

		&:first-child {
			padding-left: 0;

			&:before {
				display: none;
			}
		}

		&:before {
			position: absolute;
			left: pxToRem(10);
			@include sq-transform(rotate(180deg));
		}
	}
}
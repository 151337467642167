

// HTML 5 Boilerplate Print Stylesheet 
* {
    box-shadow: none !important;
    text-shadow: none !important;
}

a {
    text-decoration: underline;
}

pre,
blockquote {
    page-break-inside: avoid;
}

thead {
    display: table-header-group; // h5bp.com/t
}

tr,
img {
    page-break-inside: avoid;
}

img {
    max-width: 100% !important;
}

p,
h2,
h3 {
    orphans: 3;
    widows: 3;
}

h2,
h3 {
    page-break-after: avoid;
}

.section {
    &__link {
        display: none;
    }
}

.external-link:after {
    display: none !important;
}
/* Module: S */
.licence-calculator {

    .ant-btn-primary {
        display: none;
    }

    &__cost {
        .licence-calculator__header {
            display: none;
        }
    }

    &__note {
        color: $pine-green;
    }

    &__fees,
    &__total {
        padding: pxToRem(20) 0 0 0;
        
        dl {
            display: block;
            width: 100%;
            border-bottom: 2px solid $ocean;
            color: $pine-green;
            padding: 0 0 pxToRem(18) 0;
        }

        dt {
            font-size: pxToRem(22);
            padding: 0;
        }

        h4,
        p {
            color: $pine-green;
            padding: pxToRem(20) pxToRem(20) 0 pxToRem(20);
        }
    }

    &__footer {

        &-form,
        &-finalise-buttons {
            display: none;
        }

        &-total {
            padding: 0;
            margin: 0 0 pxToRem(20) 0;
            width: 100%;
            float: left;

            h3 {
                border-bottom: 2px solid $ocean;
                color: $pine-green;
                float: left;
                width: 100%;
                padding: 0 0 pxToRem(18) 0 !important;
                margin: 0 0 pxToRem(20) 0;
            }

            p {
                width: 100%;
                color: $pine-green;
                display: block;
                float: left;
                padding: 0 pxToRem(20);
            }
        }

        &-note {
            padding: pxToRem(10) pxToRem(20) pxToRem(20) pxToRem(20);
            float: left;
            width: 100%;
            color: $pine-green;
        }

        &-fee {
            margin-top: 0;
            top: 0;
            right: 0;
            text-align: right;
            color: $pine-green;
        }

    }
}
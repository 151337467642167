
h1 {
    font-size: 2.250em; //36
    line-height: 1.11;
    padding: pxToRem(30) 0;
}

h2 {
    font-size: 2.000em; //32
    line-height: 1.13;
    padding: pxToRem(20) 0 pxToRem(42) 0;

    &:after {
        bottom: pxToRem(26);
    }
}

.section {
    &__title {
        font-size: 1.250em; //20
    }

    &__link {
        a { 
            line-height: 1.8;      
        }
    }
}

.content {
    img {
        &.left {
            float: left;
            margin: pxToRem(5) pxToRem(20) pxToRem(15) 0;
        }

        &.left-50 {
            float: left;
            width: 50%;
            margin: pxToRem(5) pxToRem(20) pxToRem(15) 0;
        }

        &.left-40 {
            float: left;
            width: 40%;
            margin: pxToRem(5) pxToRem(20) pxToRem(15) 0;
        }

        &.left-30 {
            width: 30%;
            float: left;
            margin: pxToRem(5) pxToRem(20) pxToRem(15) 0;
        }

        &.right {
            float: right;
            margin: pxToRem(5) 0 pxToRem(15) pxToRem(20);
        }

        &.right-50 {
            float: right;
            width: 50%;
            margin: pxToRem(5) 0 pxToRem(15) pxToRem(20);
        }

        &.right-40 {
            float: right;
            width: 40%;
            margin: pxToRem(5) 0 pxToRem(15) pxToRem(20);
        }

        &.right-30 {
            width: 30%;
            float: right;
            margin: pxToRem(5) 0 pxToRem(15) pxToRem(20);
        }
    }

    p { 
        &.left {
            float: left;
            padding: pxToRem(15) pxToRem(20) pxToRem(15) 0;
        }

        &.left-50 {
            float: left;
            width: 50%;
            padding: pxToRem(15) pxToRem(20) pxToRem(15) 0;
        }

        &.left-40 {
            float: left;
            width: 40%;
            padding: pxToRem(15) pxToRem(20) pxToRem(15) 0;
        }

        &.left-30 {
            width: 30%;
            float: left;
            padding: pxToRem(15) pxToRem(20) pxToRem(15) 0;
        }

        &.right {
            float: right;
            padding: pxToRem(15) 0 pxToRem(15) pxToRem(20);
        }

        &.right-50 {
            float: right;
            width: 50%;
            padding: pxToRem(15) 0 pxToRem(15) pxToRem(20);
        }

        &.right-40 {
            float: right;
            width: 40%;
            padding: pxToRem(15) 0 pxToRem(15) pxToRem(20);
        }

        &.right-30 {
            width: 30%;
            float: right;
            padding: pxToRem(15) 0 pxToRem(15) pxToRem(20);
        }
    }

    .landing-tiles {
        padding-left: 0;
        padding-right: 0;
        margin-left: pxToRem(-10);
        margin-right: pxToRem(-10);
    }
}

/*
--------------------
Modules
--------------------
*/
/* Module: S */
.stripe {
	padding: pxToRem(20) 0;

	&__wrapper {
		padding: pxToRem(31) pxToRem(30) pxToRem(31) pxToRem(30);
		@include sq-flex-box;
	}

	&__title {
		padding-top: pxToRem(5);
		width: 70%;

		& + .stripe__button {
			text-align: right;
		}
	}

	&__button {
		padding-top: 0;
		width: 30%;
	}

	&--tile {
		padding: pxToRem(25) 0;

		.stripe {
			&__wrapper {
				@include sq-flex-wrap(wrap);
				padding: pxToRem(30) pxToRem(30) pxToRem(45) pxToRem(30);
			}

			&__title {
				width: 100%;
				padding-top: 0;
			}

			&__button {
				width: 100%;
				text-align: left;
				padding-top: pxToRem(43);
			}
		}
	}
}
/* Module: S */
.video-block {
	padding: pxToRem(20) 0;
	
	&__wrapper {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		height: 0;
	}
	
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	button {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		padding: 0;
		background-size: cover;
		background-position: center;
		display: none;

		&:before {
	        content: '';
	        width: pxToRem(103);
	        height: pxToRem(54);
	        display: block;
	        position: absolute;
	        top: 50%;
	        left: 50%;
	        margin: pxToRem(-27) 0 0 pxToRem(-52);
	        border-radius: pxToRem(27);
	        background-color: $teal-blue;
	        background-image: linear-gradient(116deg, $nice-blue, $teal-blue);
		}

		&:after {
	        content: url(../mysource_files/play.svg);
	        width: pxToRem(103);
	        height: pxToRem(54);
	        line-height: 54px;
	        display: block;
	        position: absolute;
	        top: 50%;
	        left: 50%;
	        margin: pxToRem(-27) 0 0 pxToRem(-52);
		}

		&:hover {
			&:before {
				background-color: $ocean;
				background-image: none;
			}
		}
	}
}
/* Module: S */
.filters {
	padding: pxToRem(22) 0 pxToRem(20) 0;

	&__info {
		padding: pxToRem(11) 0 pxToRem(10) 0;
	}

	&__list {
        float: right;
        width: auto;
	}

	&__item {
        width: auto;
	}
}
/* Module: S */
.news-tiles {
	a {
		min-height: pxToRem(40);
		height: 100%;
    	page-break-inside: avoid;
	}

	img {
    	page-break-inside: unset;
	}	

	&__wrapper {
		@include sq-flex-box();
		@include sq-flex-wrap(wrap);
		padding: 0 pxToRem(10);
	}

	&__item {
		padding: pxToRem(10);
		width: 50%;

		&--featured {
			.news-tiles {
				&__title {
					font-size: 1.750em; //28
					line-height: 1.29;
					max-width: pxToRem(484);
				}
			}
		}

		&--single {
			width: 100%;
		}
	}

	&--featured {
		.news-tiles {
			&__item {
				&:first-child {
					width: 100%;

					a {
						min-height: pxToRem(348);
					}
				}
			}
		}
	}

}



/* Module: S */
.block {
	&.collapsed {
		&:after {
			display: none;
		}

		.block {
			&__wrapper {
				max-height: initial;
			}

			&__more {
				display: none;
			}
		}
	}
}
/* Module: S */
.licence-calculator {
	padding: 0;

	&__footer {
		input[type="text"],
		input[type="email"] {
			width: pxToRem(359);
		}

		&-fee {
			position: absolute;
			top: 50%;
			margin-top: pxToRem(-15);
		}
	}

	&__cost {
		position: absolute;
		padding-top: 0;
		top: 0;
	}

	.ant-form-item-control-wrapper {
		text-align: right;
	}

	.ant-btn-primary {
		margin-right: pxToRem(20);
		margin-top: pxToRem(-40);
	}

	.section__column {
		position: relative;
	}
}
/* Module: S */
.tile {
	&__text {
		padding-top: pxToRem(13);
	}

	&__content {
		h3 {
			font-size: 1.375em; //22
		}

		p {
			padding: pxToRem(22) 0 0 0;
		}
	}

	&__link {
		padding-top: pxToRem(22);
	}

	&--large {
		.tile {
			&__wrapper {
				padding: pxToRem(53) pxToRem(30) pxToRem(65) pxToRem(30);
			}

			&__title {
				font-size: 1.375em; //22
			}

			&__text {
				padding-top: pxToRem(22);
			}
		}
	}

	&--separated {
		padding: pxToRem(25) 0;

		&:first-child {
			padding-top: pxToRem(10);
		}

		&:last-child {
			padding-bottom: pxToRem(50);
		}
	}

	&--transparent {
		.tile {
			&__wrapper {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}
/* Module: S */
.banner {
	height: pxToRem(230);

	&__wrapper {
		padding: pxToRem(64) pxToRem(30);
	}

	h1 {
		padding: 0;
	}

	form {
		padding: pxToRem(24) 0 0 0;
		@include placeholder-color($battleship-grey);

		&:before {
			content: 'I want to';
			font-size: 24px; //24
			font-weight: 500;
			line-height: 70px; 
			color: $evergreen;
			top: pxToRem(24);
			left: pxToRem(34);
		}

		&:after {
			display: none;
		}
	}

	input[type="text"] {
		height: pxToRem(70);
		font-size: 1em; //16
		line-height: 1.4;
		padding: pxToRem(24) pxToRem(156) pxToRem(24) pxToRem(147);
		box-shadow: 0 pxToRem(2) pxToRem(10) 0 rgba($black, 0.21);
	}

	input[type="submit"] {
		width: pxToRem(136);
		height: pxToRem(70);
		font-size: 1.250em; //20
  		font-weight: bold;
		top: pxToRem(24);
		text-indent: 0;
		color: $white;
		@include font-smoothing;
		padding-right: pxToRem(8);
	}

	&--image {
		height: pxToRem(380);
	}

	&--search {
		height: pxToRem(400);
	}

	.search {
    	&__partial-list {
    		left: pxToRem(128);
    		right: pxToRem(136);
    	}
    }
}
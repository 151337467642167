/* Module: S */
.gallery {
	&__item {
		float: none;
		margin-bottom: pxToRem(20);
	}

	&__text {
		padding: pxToRem(20);
		background-color: $white;
	}

	&__wrapper {
		background-color: transparent;
	}

	.slick {
		&-arrow {
			display: none!important;
		}

		&-dots {
			display: none!important;
		}

		&-track {
			width: auto!important;
		}

		&-slide {
			opacity: 1!important;
			left: 0!important;
			width: 100%!important;

			&:last-child {
				.gallery {
					&__item {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
/* Module: S */
.news-tiles {
	margin: pxToRem(-10);
	
	&__wrapper {
		padding: 0;
	}

	&__intro {
		padding: pxToRem(60) 0 0 0;
		margin-bottom: pxToRem(-10);

		.news-tiles {
			&__date {
				margin-left: pxToRem(28);
			}
		}
	}

	&__header {
	    padding: pxToRem(60) pxToRem(10) pxToRem(15) pxToRem(10);
	}

	&__item {
		width: 25%;

		&--featured {
			.news-tiles {
				&__content {
					padding: pxToRem(40) pxToRem(30) pxToRem(53) pxToRem(30);
				}
			}

			a {
			    &:after {
			        bottom: pxToRem(42);
			        left: pxToRem(30);
			    }

				&:hover,
				&:focus {
					&:after {
			        	left: pxToRem(35);
					}
				}
			}
		}

		&--single {
			width: 100%;
		}
	}

	&--featured {
		.news-tiles {
			&__item {
				&:first-child {
					width: 50%;
				}
			}
		}
	}
}
/* Module: S */
.tabs {
	&__title {
		button {
	    	color: $white;
    		background-color: $ocean;
    		
			&:before,
			&:after {
				display: none;
			}
		}
	}

	&__text {
		display: block;
	}
}
/* Module: S */
.tile {
	&__wrapper {
		background-color: $white;
		color: $battleship-grey;
		padding: pxToRem(30) pxToRem(20);
		height: 100%;
	}

	&__image {
		padding-bottom: pxToRem(22);

		img {
			width: 100%;
		}
	}

	&__title {
		font-size: 1.250em; //20
		font-weight: 500;
		color: $twilight-blue;
	}

	&__sub-title {
		font-size: 1em; //16
		line-height: 1.5;
		font-weight: 500;
		color: $spruce;
	}

	&__content {
		h3 {
			font-size: 1.250em; //20
			line-height: 1.4;
			font-weight: 500;
			padding: 0;
		}

		p {
			font-size: 1em; //16
			line-height: 1.5;
			padding: pxToRem(26) 0 0 0;
			margin: 0;
		}
	}

	&__text {
		font-size: 1em; //16
		line-height: 1.5;
		padding-top: pxToRem(13);
		color: $battleship-grey-two;
	}

	&__list {
		padding-top: pxToRem(20);

	    ul {
	        list-style-type: none;
	        padding: 0;
	        margin: 0;

	        li {
	            padding: pxToRem(10) 0;
	            margin: 0;
	            font-size: 0.875em; //14
	            line-height: 1.5;
	            position: relative;
	        }
	    }

	    a {
	    	display: inline-block;
	    	padding-left: pxToRem(34);
	    	position: relative;
	    	font-weight: bold;
	    	color: $twilight-blue;
	    	text-decoration: none;

		    &:before {
		        content: url(../mysource_files/arrow-long-right-teal-light.svg);
		        width: pxToRem(25);
		        height: pxToRem(16);
		        line-height: pxToRem(16);
		        display: block;
		        position: absolute;
		        top: 50%;
		        margin-top: pxToRem(-8);
		        left: 0;
		        @include sq-transition(left 0.3s);
			}
			
			&.external-link {
				&:before {
					content: url(../mysource_files/icon-external-link.svg);
					width: pxToRem(17);
					height: pxToRem(17);
					left: pxToRem(3);
					@include sq-transition(left 0.3s);
				}
				&:hover,
				&:focus {
	
					&:before {
						left: pxToRem(8);
					}
				}

			}

		    &:hover,
		    &:focus {
		        text-decoration: none;

		        &:before {
		            left: pxToRem(5);
		        }
		    }
	    }
	}

	&__link {
		padding-top: pxToRem(26);

		a {
			display: inline-block;
			font-size: 1em; //16
			font-weight: bold;
			padding-right: pxToRem(43);
			position: relative;
			text-decoration: none;
			color: $twilight-blue;

		    &:hover,
		    &:focus {
		        &:before {
		            right: 0;
		        }
		    }

		    &:before {
		        content: url(../mysource_files/arrow-long-right-teal-light.svg);
		        width: pxToRem(25);
		        height: pxToRem(16);
		        line-height: pxToRem(16);
		        display: block;
		        position: absolute;
		        top: 50%;
		        margin-top: pxToRem(-8);
		        right: pxToRem(5);
		        @include sq-transition(right 0.3s);
			}
			
			&.external-link {
				&:before {
					content: url(../mysource_files/icon-external-link.svg);
					width: pxToRem(17);
					height: pxToRem(17);
				}
			}
		}
	}

	&--large {
		.tile {
			&__wrapper {
				padding: pxToRem(30) pxToRem(20) pxToRem(40) pxToRem(20);
			}

			&__text {
				padding-top: pxToRem(26);
			}
		}
	}

	&--separated {
		padding: pxToRem(10) pxToRem(20);

		&:first-child {
			padding-top: pxToRem(20);
		}

		&:last-child {
			padding-bottom: pxToRem(40);
		}
	}

	&--transparent {
		@include font-smoothing;

		.tile {
			&__wrapper {
				background-color: transparent;
				border-bottom: pxToRem(2) solid $teal-blue;
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	&--inverse {
		@include font-smoothing;

		.tile {
			&__wrapper {
				background-color: $ocean;
				color: $white;
			}

			&__title {
				color: $white;
			}

			&__link {
				a {
					&.external-link {
						&:before {
							content: url(../mysource_files/icon-external-link-white.svg);
						}
					}
				}
			}
		}

		a {
			color: $white;

			&.button--inverse {
				color: $twilight-blue;
			}
		}
	}
}
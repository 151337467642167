/* Module: S */
.banner {
	width: 100%;
	height: pxToRem(210);
	background-color: $teal-blue;
	background-image: linear-gradient(121deg, $nice-blue, $teal-blue);

	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	&__wrapper {
		padding: pxToRem(30) pxToRem(10);
		@include sq-flex-box();
		@include sq-flex-direction(column);
		@include sq-justify-content(center);
		height: 100%;
	}

	h1 {
		text-align: center;
		padding: 0;
		color: $white-two;
		@include font-smoothing();
	}

	form {
		padding: pxToRem(20) 0 0 0;
		@include placeholder-color(transparent);
		position: relative;
		width: 100%;
		max-width: pxToRem(753);
		margin: 0 auto;

		&:before {
			content: 'Search';
			font-size: 1.125em; //18
			font-weight: 500;
			line-height: 60px;
			color: $spruce;
			position: absolute;
			top: pxToRem(20);
			left: pxToRem(22);
		}

		&:after {
			content: url(../mysource_files/search.svg);
			width: pxToRem(21);
			height: pxToRem(22);
			display: block;
			position: absolute;
			top: pxToRem(39);
			right: pxToRem(19);
		}
	}

	input[type="text"] {
		@include clear-default-appearance;
		background-color: $white;
		color: $pine-green;
		width: 100%;
		height: pxToRem(60);
		font-size: 1.125em; //18
		line-height: 1.46;
		border-radius: pxToRem(36);
		border: none;
		margin: 0;
		padding: pxToRem(17) pxToRem(80) pxToRem(17) pxToRem(90);
		outline: none;

		&:focus {
			background-color: $white-four;
		}
	}

	input[type="submit"] {
		@include clear-default-appearance;
		background-color: $teal-blue;
		width: pxToRem(60);
		height: pxToRem(60);
		border-top-right-radius: pxToRem(36);
		border-bottom-right-radius: pxToRem(36);
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border: none;
		margin: 0;
		padding: 0;
		position: absolute;
		top: pxToRem(20);
		right: 0;
		text-indent: -99999px;
		display: block;
		background-image: none;
		outline: none;

		&:hover,
		&:focus,
		&:active {
			background-color: $petrol;
		}
	}

	&--search {
		height: pxToRem(200);

		.banner {
			&__wrapper {
				@include sq-justify-content(flex-start);
			}
		}
	}

	&--image {
		h1 {
			color: $pine-green;
		}
	}

	.search {
    	&__partial-list {
    		left: pxToRem(80);
    		right: pxToRem(60);
    		margin-top: 0;
    	}
    }
}
/* Module: S */
.gallery {
	padding: pxToRem(20) 0;
	@extend %clearfix;

	img {
		display: block;
	}

	&__wrapper {
		position: relative;
		background-color: $white;
		padding-bottom: pxToRem(20);
	}

	&__text {
		padding: pxToRem(20) pxToRem(20) 0 pxToRem(20);
		font-size: 0.875em; //14
		line-height: 1.5;
		color: $battleship-grey;
	}

	&__item {
		float: left;
	}

	.slick {
		&-arrow {
			top: auto;
			margin: 0;
			bottom: pxToRem(20);
			padding: 0;
			z-index: 9;
			background-color: transparent;
			background-repeat: no-repeat;
			width: pxToRem(40);
			height: pxToRem(24);
			text-indent: -9999px;
			border: none;
			opacity: 1;
			@include sq-transition(opacity 0.3s);

			&:hover {
				opacity: 0.6;
			}
		}

		&-prev {
			left: pxToRem(10);
			right: auto;

		    &:after {
		        content: url(../mysource_files/arrow-long-right-teal-light.svg);
		        width: pxToRem(25);
		        height: pxToRem(16);
		        line-height: pxToRem(16);
		        display: block;
		        position: absolute;
		        top: 50%;
		        margin-top: pxToRem(-8);
		        left: pxToRem(10);
		        text-indent: 0;
		        @include sq-transform(rotate(180deg));
		    }
		}

		&-next {
			right: pxToRem(10);
			left: auto;

		    &:after {
		        content: url(../mysource_files/arrow-long-right-teal-light.svg);
		        width: pxToRem(25);
		        height: pxToRem(16);
		        line-height: pxToRem(16);
		        display: block;
		        position: absolute;
		        top: 50%;
		        margin-top: pxToRem(-8);
		        right: pxToRem(10);
		        text-indent: 0;
		    }
		}

		&-dots {
			padding: pxToRem(20) pxToRem(60) 0 pxToRem(60);
			margin: 0;

			li {
				padding: 0;

				& + li {
					margin: 0;

				}

				&.slick-active {
					button {
						&:after {
							background-color: $teal-blue;
						}
					}
				}

				&:before {
					display: none;
				}
			}

			button {
				-webkit-appearance: none;
				text-indent: -9999px;
				width: pxToRem(23);
				height: pxToRem(23);
				background-color: transparent;
				border: none;
				position: relative;
				padding: 0;

				&:after {
					content: '';
					position: absolute;
					top: pxToRem(7);
					left: pxToRem(7);
					width: pxToRem(9);
					height: pxToRem(9);
					background-color: $light-grey;
					border: none;
					border-radius: 100%;
				}
			}
		}
	}
}